import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import classes from "./ProjectDetail.module.css";
import star from "./Images/5star.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import imageblg from "./Images/building.png";
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Button } from "react-scroll";
import HTMLFlipBook from "react-pageflip";
import { useNavigate } from "react-router-dom";

const ProjDeails = () => {
    const [data, setData] = useState('');
    const [data2, setData2] = useState('');
    const [tim, setTim] = useState();
    const [cookies, setCookie, removeCookie] = useCookies(['detail']);
    const [selectedImage, setSelectedImage] = useState(null);
    let myData;

    const getDetail = async (rera, id) => {
        console.log(id, rera)
        try {
            const res = await axios.get('https://primeedgeproperties.in/projDet', { params: { rera, id } })

            setData(res.data.data1); setData2(res.data.data2);
            myData = res.data.data1

            console.log(data)
            console.log(data2)
        } catch (err) {
            console.log(err)
        }
    }

    const myfun = () => {
        setTim(1)
    }

    const Property_name_Cookie = (propertyname) => {
        setCookie('NameOfProperty', propertyname)
    }

    useEffect(() => {
        let rera = cookies.detail
        let id = cookies.detailId
        //console.log(rera)
        //removeCookie('detail', cookies.detail)

        getDetail(rera, id)
        setTimeout(myfun, 100)

    }, [tim])

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
            slidesToSlide: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1000 },
            items: 1,
            slidesToSlide: 1
        },
        desktop1: {
            breakpoint: { max: 1000, min: 768 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };


    const isviewActive = true;




    return (

        < div >

            {data && data.map((item) => (
                <div className={classes.container}>

                    <div className={classes.top1}>
                        <div className={classes.left}>
                            <div className={classes.leftTop}>
                                {selectedImage && (
                                    <div className={classes.modal} onClick={() => setSelectedImage(null)}>
                                        <img src={selectedImage} alt="Selected Cover" />
                                    </div>
                                )}
                                {!selectedImage && (
                                    <div className={classes.modal}>
                                        <img src={`https://primeedgeproperties.in/BUILDER/${item.COVER.split(',')[0]}`} alt="Default Cover" />
                                    </div>
                                )}
                            </div>
                            <div className={classes.coverImages}>
                                {item.COVER.split(',').map((cover, index) => (
                                    <img
                                        key={index}
                                        src={`https://primeedgeproperties.in/BUILDER/${cover}`}
                                        alt={`Cover ${index}`}
                                        onClick={() => setSelectedImage(`https://primeedgeproperties.in/BUILDER/${cover}`)}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className={classes.right}>
                            <h1>{item.PROJECT_NAME}</h1>
                            <p>{item.LOCALITY} , {item.CITY}</p>
                            <hr />
                            <h2>₹ {item.MIN_PRICE} - {item.MAX_PRICE}</h2>
                            <img className={classes.star} src={star} alt="5 star rating" />
                            <p>Status : <b>{item.POSSESSION}</b></p>
                            {item.POSSESSION === 'Under Construction' && (<p>Completion Date: {item.POSSESSION_DATE}</p>)}
                            <p>{item.BHK} </p>
                            <p>{item.MIN_SQFT} - {item.MAX_SQFT} SQ.FT (Carpet)</p>

                            <div className={classes.psize}>
                                <div className={classes.logimg}><img src={imageblg} alt="Load" /></div>
                                <div>
                                    <p>Project Size :</p>
                                    <p>{item.TOTAL_UNITS} units ✦  {item.TOTAL_ACRES} Acres</p>
                                </div>
                            </div>

                            <button className={classes.btn}><Link onClick={() => { Property_name_Cookie(item.PROJECT_NAME) }} className={classes.btn} to='/contactus'>Request a Call Back</Link></button>
                        </div>
                    </div>

                    <div className={classes.tabs}>
                        <header className={classes.nav}>
                            <nav className={classes.nav__container__actions}>
                                <ul>
                                    <li>
                                        <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="overview">
                                            Overview
                                        </Button>
                                    </li>
                                    <li>
                                        <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="regulatoryInfo">
                                            Regulatory Information
                                        </Button>
                                    </li>
                                    <li>
                                        <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="brochure">
                                            Brochure
                                        </Button>
                                    </li>
                                    <li>
                                        <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="video">
                                            Video
                                        </Button>
                                    </li>
                                    <li>
                                        <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="amenities">
                                            Amenities
                                        </Button>
                                    </li>
                                    <li>
                                        <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="specifications">
                                            Specifications
                                        </Button>
                                    </li>
                                    <li>
                                        <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="propertyInfo">
                                            Property Information
                                        </Button>
                                    </li>
                                    <li>
                                        <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="priceList">
                                            Price List
                                        </Button>
                                    </li>
                                </ul>
                            </nav>
                        </header>

                        <section className={classes.card} id="overview">
                            <h3>{item.PROJECT_NAME} - Project Info</h3>
                            <div className={classes.underline}></div>
                            <p>{item.OVERVIEW}</p>
                        </section>

                        <section className={classes.card2} id="regulatoryInfo">
                            <h3>{item.PROJECT_NAME} - Regulatory Information</h3>
                            <div className={classes.underline}></div>
                            <div className={classes.rer}><h4>Project RERA Reg.</h4><p>{item.RERA}</p></div>
                        </section>

                        <section className={classes.card2} id="brochure">
                            <h3>{item.PROJECT_NAME} Brochure</h3>
                            <div className={classes.underline}></div>
                            <div className={classes.brochureContainer}>
                                <HTMLFlipBook className={classes.innerBrochureContainer}
                                    width={300}
                                    height={200}
                                >
                                    <div className={classes.coverPage}></div>
                                    {item.BROCHURE.split(',').map((cover, index) => (
                                        <img className={classes.broucher} key={index} src={`https://primeedgeproperties.in/BUILDER/${cover}`} alt={`Cover ${index}`} />
                                    ))}
                                </HTMLFlipBook>
                            </div>
                        </section>

                        <section className={classes.card2} id="video">
                            <h3>{item.PROJECT_NAME} Video</h3>
                            <div className={classes.underline}></div>
                            <video className={classes.vid} width="660" controls >
                                <source src={`https://primeedgeproperties.in/BUILDER/` + item.VIDEO} type="video/mp4" />
                            </video>
                        </section>

                        <section className={classes.card2} id="amenities">
                            <h3>{item.PROJECT_NAME} - Amenities</h3>
                            <div className={classes.underline}></div>
                            <div className={classes.amenity}><p><b>Sports :</b> </p> <p className={classes.amenityVal}>{item.SPORTS.split(',').map((sport, index) => (<li key={index}>{sport}</li>))}</p></div>
                            <div className={classes.amenity}><p><b>Safety :</b> </p> <p>{item.SAFETY.split(',').map((safety, index) => (<li key={index}>{safety}</li>))}</p></div>
                        </section>

                        <section className={classes.card2} id="specifications">
                            <h3>{item.PROJECT_NAME} - Specifications</h3>
                            <div className={classes.underline}></div>
                            <p><b>Master Bedroom-Walls :</b> {item.B_WALLS}</p>
                            <p><b>Master Bedroom-Flooring :</b> {item.B_FLOOR}</p>
                            <p><b>Other Bedrooms-Flooring :</b> {item.OTHER_BFLOOR}</p>
                            <p><b>Walls :</b>  {item.WALLS}</p>
                            <p><b>Living Area-Flooring :</b> {item.L_FLOOR}</p>
                            <p><b>Bathroom :</b> {item.BATHROOM}</p>
                            <p><b>Structure :</b> {item.STRUCTURE}</p>
                        </section>
                    </div>
                    <section className={classes.card2} id="propertyInfo">
                        <div className={classes.top2}>
                            <h3>Property Information :</h3>
                            <div className={classes.underline}></div>
                            <div className={classes.top2l}>
                                <div className={classes.row}>
                                    <div className={classes.info}>
                                        <p>RERA Reg </p>
                                        <p><b>{item.RERA}</b></p>
                                    </div>
                                    <div className={classes.info}>
                                        <p>Listing Type</p>
                                        <p><b>{item.LISTING_TYPE}</b></p>
                                    </div>
                                    <div className={classes.info}>
                                        <p>Property Type</p>
                                        <p><b>{item.PROPERTY_TYPE}</b></p>
                                    </div>

                                </div>
                                <div className={classes.row}>
                                    <div className={classes.info}>
                                        <p>City</p>
                                        <p><b>{item.CITY}</b></p>
                                    </div>
                                    <div className={classes.info}>
                                        <p>Locality</p>
                                        <p><b>{item.LOCALITY}</b></p>
                                    </div>
                                    <div className={classes.info}>
                                        <p>Area</p>
                                        <p><b>{item.MIN_SQFT} - {item.MAX_SQFT}</b></p>
                                    </div>
                                </div>
                                <div className={classes.row}>
                                    <div className={classes.info}>
                                        <p>Price</p>
                                        <p><b>{item.MIN_PRICE} - {item.MIN_PRICE}</b></p>
                                    </div>
                                    <div className={classes.info2}>
                                        <p>Possession Status</p>
                                        <p><b>{item.POSSESSION}</b></p>
                                    </div>

                                </div>
                                <div className={classes.row}>
                                    <div className={classes.detailed}>
                                        <p>Detailed Address</p>
                                        <p><b>{item.D_ADDRESS}</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={classes.rates} id="priceList">
                        <table className={classes.card2}>
                            <tr className={classes.Thead}>
                                <th>Unit Type</th>
                                <th>Area</th>
                                <th>Price</th>
                            </tr>
                            {data2 && data2.map(ab =>
                                <tr className={classes.Trow}>
                                    <td><Link className={classes.lnk} to={`/projectD_bhk/${ab.ID}`} target="_blank">{ab.B_UNIT}  </Link></td>
                                    <td><Link className={classes.lnk} to={`/projectD_bhk/${ab.ID}`} target="_blank">{ab.B_SQFT} Sq.Ft. </Link></td>
                                    <td><Link className={classes.lnk} to={`/projectD_bhk/${ab.ID}`} target="_blank">{ab.B_PRICE} </Link></td>
                                </tr>
                            )}
                        </table>
                    </section>
                </div>
            ))}

        </div >
    )
}
export default ProjDeails;