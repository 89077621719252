import classes from "./All.module.css";
import image1 from "../../HOME/Projects/Images/1.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import { useCookies } from 'react-cookie';
import { Link, useNavigate } from "react-router-dom";
import Popup from "../../Popup/Popup";

function All() {
    const [info, setInfo] = useState();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [id, setId] = useState();
    const [individualData, setIndividualData] = useState()
    const [tim, setTim] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['edit']);
    const [dummy, setDummy] = useState(false)

    const navigate = useNavigate();

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const DeletePopUp = async () => {
        try {

            setIsPopupOpen(false)
            //console.log(id, 'dele')
            const res = await axios.delete(`https://primeedgeproperties.in/${id}`);
            console.log(res.data.success)

            if (res.data.success) {
                setIsPopupOpen(false)
                alert('entry deleted successfully')
            } else {
                setIsPopupOpen(false)
                alert('error, In deleting the entry')
            }
            window.location.reload()
        } catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {
        const fetchAllInfo = async () => {
            try {
                const res = await axios.get("https://primeedgeproperties.in/builder_DATA")
                setInfo(res.data);
            } catch (err) {
                console.log(err);
            }
        };

        const fetchAdminIndividual = async () => {
            try {
                const response = await axios.get('https://primeedgeproperties.in/fetchAdminIndividualProp')
                //console.log(response)
                setIndividualData(response.data)
                console.log(individualData)
            } catch (err) {
                console.log(err)
            }
            setTim(true)

        }
        fetchAllInfo();

        fetchAdminIndividual();
        // fetchAdminIndividual();
    }, [tim, dummy]);

    const handleDelete = async (ID) => {
        setIsPopupOpen(true)
        setId(ID)
        //onDelete(ID)

        /*try {
            
            const res = await axios.delete(`https://primeedgeproperties.in/${ID}`);
            console.log(res.data.success)

            if (res.data.success) {
                alert('entry deleted successfully')
            } else {
                alert('error, In deleting the entry')
            }
            window.location.reload()
        } catch (err) {
            console.log(err)
        }*/
    };

    const active = async (BID) => {
        //console.log(BID)
        try {
            const res = await axios.put("https://primeedgeproperties.in/builder_status", { BID })

            setDummy(!dummy)
        } catch (err) {
            console.log(err)
        }
    };

    const editHnd = (id) => {
        setCookie('edit', id)
        //navigate('/editPage2')
        navigate('/editPage')

    }
    return (
        <>
            <section className={classes.main_container}>
                <h1>All Product Detailes</h1>
                <Link to="/dashboard" className={classes.backBtn}>Back to Dashboard</Link>
                <div className={classes.container}>
                    {info && info.map((record, index) => (
                        <div className={classes.card} key={index}>
                            <div className={classes.left}>
                                <div className={classes.L1}>
                                    <p className={classes.area} ><b>ID :</b> <span className={classes.id}>{record.ID}</span></p>
                                    <div className={classes.row}><p><b>Project Profile : </b> </p> <img className={classes.imgsize} src={`https://primeedgeproperties.in/BUILDER/` + record.THUMBNAIL} alt="Prime edge" /></div>
                                    <p className={classes.area}><b>Project Address :</b> {record.D_ADDRESS}</p>
                                    <p className={classes.area}><b>Project BHK :</b> {record.BHK}</p>

                                </div>
                                <div className={classes.R1}>
                                    <p className={classes.area}><b>Project Name :</b> {record.PROJECT_NAME}</p>
                                    <div className={classes.row}><p><b>Project Cover : </b></p> <div className={classes.imgContainer}> {record.COVER.split(',').map((cover, index) => (
                                        <img className={classes.image} key={index} src={`https://primeedgeproperties.in/BUILDER/${cover}`} alt={`Cover ${index}`} />
                                    ))}</div></div>
                                    <p className={classes.area}><b>Project Price :</b> ₹ {record.MIN_PRICE} - ₹ {record.MAX_PRICE}</p>
                                    <p className={classes.area}><b>Project SQFT :</b> {record.MIN_SQFT} - {record.MAX_SQFT}</p>
                                </div>
                            </div>
                            <div className={classes.right}>
                                <button className={classes.delete} onClick={() => handleDelete(record.ID)}>DELETE</button>
                                <button className={classes.edit} onClick={() => { editHnd(record.ID) }}>EDIT</button>
                                <button className={`${record.B_STATUS ? classes.firstclass : classes.secondclass}`} onClick={() => { active(record.ID) }}>{record.B_STATUS ? 'Deactivate' : 'Activate'}</button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {isPopupOpen && <Popup onClose={closePopup} onDelete={DeletePopUp} txt1='Please Confirm!' txt2='This action will permanently delete this property' AdminDel='.' />}



        </>
    );
};
export default All;