import classes from "./ProjectBhk.module.css";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import image from "./Images/floor-plan.png";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useCookies } from 'react-cookie';

const ProjectBhk = () => {
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(['detail']);
    console.log(data3)
    const { ID } = useParams();

    const Property_name_Cookie = (propertyname) => {
        setCookie('NameOfProperty', propertyname, { path: '/' })
    }

    useEffect(() => {
        axios.get(`https://primeedgeproperties.in/detail_bhk/` + ID)
            .then(res => { setData(res.data.data1); setData2(res.data.data2); setData3(res.data.data3) })
    }, []);
    return (
        <>

            <div className={classes.container}>
                <div className={classes.top1}>
                    {data && data.map(record => (
                        <div className={classes.left}>
                            {record.B_LAYOUT ? <img src={`https://primeedgeproperties.in/BUILDER/` + record.B_LAYOUT} alt="Layout Image " /> : <img src={image} alt="Layout Image " />}
                        </div>
                    ))}

                    <div className={classes.right}>
                        {data && data.map((record) => (
                            <div>
                                {data2 && data2.map(rec => (
                                    <div>
                                        <h2>{record.B_UNIT}  {record.B_SQFT} Sq. Ft. in {rec.PROJECT_NAME}</h2>
                                        <p>{rec.LOCALITY}, {rec.CITY}</p>
                                        <br />
                                        <h2>₹ {record.B_PRICE}</h2>



                                        <p className={classes.para}>Status: <b>{rec.POSSESSION}</b></p>
                                        <p className={classes.para}>{rec.BHK}  from {rec.MIN_SQFT} Sq. Ft. to {rec.MAX_SQFT} Sq. Ft. (Carpet)</p>
                                        <button className={classes.btn}><Link onClick={() => { Property_name_Cookie(rec.PROJECT_NAME) }} className={classes.btn} to='/contactus'>Request a Call Back</Link></button>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                {data2 && data2.map((rec) => (
                    <>
                        <div className={classes.top2}>
                            <p>Project RERA Reg . <b>{rec.RERA}</b></p>
                            <div className={classes.underline}></div>
                        </div>

                        {data && data.map((record) => (
                            <>
                                <div className={classes.thumnbnail}>
                                    <div className={classes.top}>
                                        <div className={classes.left}>
                                            <img src={`https://primeedgeproperties.in/BUILDER/` + rec.THUMBNAIL} alt="Layout Image " />
                                        </div>
                                        <div className={classes.right}>
                                            <h3>{rec.PROJECT_NAME}</h3>
                                            <p>{rec.LOCALITY}, {rec.CITY}</p>
                                            <h3>₹ {rec.MIN_PRICE} to {rec.MAX_PRICE}</h3>
                                            <p>{rec.BHK}  from {rec.MIN_SQFT} Sq. Ft. to {rec.MAX_SQFT} Sq. Ft. (Saleable)</p>
                                            <Link to='/contactus' onClick={() => Property_name_Cookie(rec.PROJECT_NAME)}><button className={classes.btnEnquire}>GET A CALL BACK</button></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.relate}>
                                    <h4 className={classes.layHead}>{rec.PROJECT_NAME} - Floor Plans</h4>
                                    <div className={classes.underline}></div>
                                    {data3 && data3.map((three) => (
                                        <div className={classes.border}>
                                            <p className={classes.layHead2}>{three.B_UNIT}  {three.B_SQFT} Sq. Ft.</p>

                                            <div className={classes.relate_layout}>
                                                <div className={classes.left}>
                                                    {three.B_LAYOUT ? <img src={`https://primeedgeproperties.in/BUILDER/` + three.B_LAYOUT} alt="Layout Image " /> : <img src={image} alt="Layout Image " />}
                                                </div>
                                                <div className={classes.right}>
                                                    <p>{three.B_UNIT} </p>
                                                    <p>Saleable Area : <b>{three.B_SQFT} Sq. Ft.</b></p>
                                                    <p>₹ {three.B_PRICE}</p>
                                                </div>
                                                <div className={classes.right2}>
                                                    <Link to={`/projectD_bhk/${three.ID}`} target="_blank" className={classes.dbtn}>See Details</Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </>
                        ))}

                    </>
                ))}

            </div>

        </>
    );
};
export default ProjectBhk;