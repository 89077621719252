import axios from 'axios';
import React, { useEffect, useState } from 'react'
import classes from './AgentEdit.module.css';
import { useCookies, Cookies } from 'react-cookie';
import Popup from '../Popup/Popup';

const UserEdit = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [data, setData] = useState('');
    const [img1, setImg1] = useState();
    const [img, setImg2] = useState();

    const [already, setAlready] = useState(false);
    const [myload, setMyload] = useState(false);


    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const closePopup = () => {
        setIsPopupOpen(false);
        setAlready(false);
        setMyload(false)
    };


    const getData = async (id) => {

        console.log(id, 'PI')
        try {
            const res = await axios.get('https://primeedgeproperties.in/agentPropEdit', { params: { id } });
            setData(res.data[0])
            console.log(res.data[0])


        } catch (err) {
            console.log(err)
        }
    }


    const hndChg = (e) => {
        //setData(e.target.value)
    }

    const hndChg1 = (e) => {
        const name = e.target.name;
        setData((prevState) => ({
            ...prevState,
            [name]: e.target.value
        }));

    }

    const hndChg2 = (e) => {
        setImg1(e.target.files[0])
    }

    const hndChg3 = (e) => {
        setImg2(e.target.files)
    }

    const hndSbt = async (e) => {
        e.preventDefault()
        setMyload(true)
        const formData = new FormData();
        formData.append('SIGNUP_ID', data.SIGNUP_ID)
        formData.append('CUST_PURP', data.CUST_PURP)
        formData.append('PROP_ID', data.PROP_ID)
        formData.append('PROPERTY_NAME', data.PROPERTY_NAME)
        formData.append('BUILDING_TYPE', data.BUILDING_TYPE)
        formData.append('FURNISHING', data.FURNISHING)
        formData.append('FLOOR_NO', data.FLOOR_NO)
        formData.append('BALCONY', data.BALCONY)
        formData.append('PROPERTY_OWNER', data.PROPERTY_OWNER)
        formData.append('LOCALITY', data.LOCALITY)
        formData.append('BHK', data.BHK)
        formData.append('D_ADDRESS', data.D_ADDRESS)
        formData.append('SQFT', data.SQFT)
        formData.append('PRICE', data.PRICE)
        formData.append('PROP_IMAGE', img1 ? img1 : data?.PROP_IMAGE)

        { data.PROPERTY_TYPE === 'Commercial' && formData.append('CEILING_HT', data.CEILING_HT) }
        formData.append('CONTACT', data.CONTACT)
        formData.append('PROPERTY_TYPE', data.PROPERTY_TYPE)
        formData.append('PARKING', data.PARKING)
        formData.append('TOTAL_FLOOR', data.TOTAL_FLOOR)
        formData.append('AGE_OF_PROPERTY', data.AGE_OF_PROPERTY)
        formData.append('OWNER_NAME', data.OWNER_NAME)
        formData.append('CITY', data.CITY)
        formData.append('BATHROOM', data.BATHROOM)
        formData.append('NEGOTIABLE', data.NEGOTIABLE)
        for (let i = 0; i < img?.length; i++) {
            formData.append('COVER_IMAGE', img[i]);
        }
        formData.append('COVER_IMAGE', data?.COVER_IMAGE);
        { data.PROPERTY_TYPE === 'Commercial' && formData.append('FRONT_AREA', data.FRONT_AREA) }

        try {
            const response = await axios.put('https://primeedgeproperties.in/agentEditSubmit', formData)
            if (response.data.status === 'success') {
                setMyload(false)
                setIsPopupOpen(true);
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        let id = cookies.PROPID;
        getData(id);

    }, [])

    return (
        <div>
            <div className={classes.hdHold}>
                <p className={classes.hd1}>Fill Out Property Details</p>
            </div>
            <form onSubmit={hndSbt} className={classes.frm1}>
                {/*<input type='text' onChange={hndChg}></input>*/}

                <div className={classes.divHold}>
                    <div className={classes.left}>

                        <div className={classes.divIn}>
                            <label>You Are Here To:</label>
                            <select name='CUST_PURP' onChange={hndChg1}>
                                <option>{data?.CUST_PURP}</option>
                                <option>Sell Property</option>
                                <option>Rent Property</option>
                            </select>
                        </div>

                        <div className={classes.divIn}>
                            <label>Property/Building Name:</label>
                            <input name='PROPERTY_NAME' type='text' defaultValue={data?.PROPERTY_NAME} onChange={hndChg1}></input>
                        </div>

                        <div className={classes.divIn}>
                            <label>Building Type:</label>
                            <select onChange={hndChg1} name='BUILDING_TYPE'>
                                <option>{data?.BUILDING_TYPE}</option>
                                <option>Bunglow</option>
                                <option>Row House</option>
                            </select>
                        </div>

                        <div className={classes.divIn}>
                            <label>Furnishing Status:</label>
                            <select onChange={hndChg1} name='FURNISHING'>
                                <option>{data?.FURNISHING}</option>
                                <option>Fully Furnished</option>
                                <option>Semi Furnished</option>
                                <option>UnFurnished</option>
                            </select>
                        </div>

                        <div className={classes.divIn}>
                            <label>Floor No:</label>
                            <input type='number' onChange={hndChg1} name='FLOOR_NO' defaultValue={data.FLOOR_NO}></input>
                        </div>

                        <div className={classes.divIn}>
                            <label>Balcony:</label>
                            <select onChange={hndChg1} name='BALCONY'>
                                <option>{data?.BALCONY}</option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div>

                        <div className={classes.divIn}>
                            <label>Property Owner</label>
                            <select onChange={hndChg1} name='PROPERTY_OWNER'>
                                <option>{data?.PROPERTY_OWNER}</option>
                                <option>Single Owner</option>
                                <option>Resale Property</option>
                                <option>New Property</option>
                            </select>
                        </div>

                        <div className={classes.divIn}>
                            <label>Locality</label>
                            <input onChange={hndChg1} name='LOCALITY' defaultValue={data?.LOCALITY}></input>
                        </div>

                        <div className={classes.divIn}>
                            <locality>BHK Type (in bhk):</locality>
                            <input type='text' onChange={hndChg1} name='BHK' defaultValue={data.BHK}></input>
                        </div>

                        <div className={classes.divIn}>
                            <label>Detailed Address: </label>
                            <textarea onChange={hndChg1} name='D_ADDRESS' defaultValue={data?.D_ADDRESS}></textarea>
                        </div>

                        <div className={classes.divIn}>
                            <label>Price:</label>
                            <input type='text' name='PRICE' onChange={hndChg1} defaultValue={data?.PRICE}></input>
                        </div>

                        <div className={classes.divIn}>
                            <label>Property Image:</label>
                            <input type='file' onChange={hndChg2} accept="image/*" name="PROP_IMAGE" ></input>
                            <img src={`https://primeedgeproperties.in/BUILDER/` + data?.PROP_IMAGE} className={classes.img1}></img>
                        </div>

                        {data?.PROPERTY_TYPE === 'Commercial' && <div className={classes.divIn}>
                            <label>Ceiling Height:</label>
                            <input type='number' name='CEILING_HT' onChange={hndChg3} defaultValue={data?.CEILING_HT}></input>
                        </div>}

                    </div> {/*left div */}

                    <div className={classes.right}>
                        <div className={classes.divIn}>
                            <label>Contact Number</label>
                            <input type='number' name='CONTACT' onChange={hndChg1} defaultValue={data?.CONTACT}></input>
                        </div>

                        <div className={classes.divIn}>
                            <label>Property Type</label>
                            <select name='PROPERTY_TYPE' onChange={hndChg1}>
                                <option>{data?.PROPERTY_TYPE}</option>
                                <option>Residential</option>
                                <option>Commercial</option>
                                <option>Land & Plotting</option>
                            </select>
                        </div>

                        <div className={classes.divIn}>
                            <label>Parking:</label>
                            <select name='PARKING' onChange={hndChg1}>
                                <option>{data?.PARKING}</option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div>

                        <div className={classes.divIn}>
                            <label>Total No Of Floors:</label>
                            <input name='TOTAL_FLOOR' onChange={hndChg1} type='number' defaultValue={data?.TOTAL_FLOOR}></input>
                        </div>

                        <div className={classes.divIn}>
                            <label>Age Of Property In Years:</label>
                            <input name='AGE_OF_PROPERTY' onChange={hndChg1} type='number' defaultValue={data?.AGE_OF_PROPERTY}></input>
                        </div>

                        <div className={classes.divIn}>
                            <label>Owner Name:</label>
                            <input name='OWNER_NAME' onChange={hndChg1} type='text' defaultValue={data?.OWNER_NAME}></input>
                        </div>

                        <div className={classes.divIn}>
                            <label>City:</label>
                            <input name='CITY' onChange={hndChg1} type='text' defaultValue={data?.CITY}></input>
                        </div>

                        <div className={classes.divIn}>
                            <locality>Carpet Area:</locality>
                            <input type='number' onChange={hndChg1} name='SQFT' defaultValue={data?.SQFT}></input>
                        </div>

                        <div className={classes.divIn}>
                            <label>No Of Bathrooms:</label>
                            <input name='BATHROOM' onChange={hndChg1} type='number' defaultValue={data?.BATHROOM}></input>
                        </div>

                        <div className={classes.divIn2}>
                            <label>Price Negotiable:</label>
                            <button type='button' onClick={hndChg1} name='NEGOTIABLE' value='Yes' defaultValue={data?.NEGOTIABLE} className={`${data?.NEGOTIABLE === 'Yes' ? classes.btn1 : classes.btn2}`}>Yes</button>
                            <button type='button' onClick={hndChg1} name='NEGOTIABLE' value='No' defaultValue={data?.NEGOTIABLE} className={`${data?.NEGOTIABLE === 'No' ? classes.btn1 : classes.btn2}`}>No</button>
                        </div>

                        <div className={classes.divIn}>
                            <label>Cover Image:</label>
                            <input onChange={hndChg3} name='COVER_IMAGE' type="file" multiple accept="image/*"></input>
                            <div className={classes.imgContainer}>
                                {data?.COVER_IMAGE?.split(',').map((brochure, index) => (

                                    <img
                                        key={index}
                                        src={`https://primeedgeproperties.in/BUILDER/${brochure}`}
                                        alt={`Cover ${index}`}
                                        className={classes.img2}
                                    />

                                ))}
                            </div>
                        </div>

                        {data?.PROPERTY_TYPE === 'Commercial' && <div className={classes.divIn2}>
                            <label>Front Area:</label>
                            <button onClick={hndChg1} type='button' name='FRONT_AREA' value='Yes' defaultValue={data?.FRONT_AREA} className={`${data?.FRONT_AREA === 'Yes' ? classes.btn1 : classes.btn2}`}>Yes</button>
                            <button onClick={hndChg1} type='button' name='FRONT_AREA' value='No' defaultValue={data?.FRONT_AREA} className={`${data?.FRONT_AREA === 'No' ? classes.btn1 : classes.btn2}`}>No</button>
                        </div>}
                    </div>{/*right div end */}
                </div>

                <button type='submit' className={classes.sbtBtn}>Submit</button>
            </form>

            {isPopupOpen && <Popup onClose={closePopup} txt1=' Changes Done SuccessFully' txt3='.' />}
            {already && <Popup onClose={closePopup} txt1='Property Already Posted' txt2='User can post one property for free' txt3='.' />}
            {myload && <Popup onClose={closePopup} txt1='Editing property' txt3='' />}

        </div>
    )
}

export default UserEdit;