import classes from "./Project.module.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from 'react-cookie';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// //<Link to={`/projectD/${pros.RERA}`} target="_blank"></Link>

const Project = () => {
    const [pro, setPro] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchAllCards = async () => {
            try {
                const res = await axios.get("https://primeedgeproperties.in/home");
                setPro(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        fetchAllCards();
    }, []);

    const Property_name_Cookie = (propertyname) => {
        setCookie('NameOfProperty', propertyname)
    }

    const hndClk = (rera, id, name) => {
        // const rera = pro;
        console.log(rera, id)
        setCookie('detail', rera)
        setCookie('detailId', id)

        const newTabUrl = 'https://primeedgeproperties.in/propDetails';

        // Open a new tab with the specified URL
        window.open(newTabUrl, '_blank');
    };
    /**Property Slider**/
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };
    return (
        <div>
            <center><h2 className={classes.head2}>Hot Selling Projects in India</h2> </center>
            <Carousel
                autoPlay={true}
                showDots={false}
                infinite={true}
                pauseOnHover={true}
                responsive={responsive}>
                {pro.map((pros) => (
                    <div className={classes.card} key={pros.ID}>

                        <img src={`https://primeedgeproperties.in/BUILDER/` + pros.THUMBNAIL} onClick={() => { hndClk(pros.RERA, pros.ID, pros.PROJECT_NAME) }} alt="top real estate" />
                        <div className={classes.info} onClick={() => { hndClk(pros.RERA, pros.ID, pros.PROJECT_NAME) }}>
                            <h3>{pros.PROJECT_NAME}</h3>
                            <p>{pros.LOCALITY} , {pros.CITY}</p>
                            <p><b>₹ {pros.MIN_PRICE} - {pros.MAX_PRICE} </b></p>
                            <div className={classes.row}>
                                <p>{pros.BHK}</p>
                                <p>{pros.MIN_SQFT} - {pros.MAX_SQFT} SQFT</p>
                            </div>
                        </div>
                        <div className={classes.btnrow}>
                            <button><Link className={classes.lnk} onClick={() => { Property_name_Cookie(pros.PROJECT_NAME) }} to="/contactus" target="_blank">Enquire Now</Link></button>
                            <button onClick={() => { hndClk(pros.RERA, pros.ID,) }}>Details</button>
                        </div>

                    </div>
                ))}
            </Carousel>
        </div>
    );
};
export default Project;