// AppContext.js

import { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [isSignUp, setIsSignUp] = useState(false);

    const toggleSignUp = () => {
        setIsSignUp((prevIsSignUp) => !prevIsSignUp);
    };

    return (
        <AppContext.Provider value={{ isSignUp, toggleSignUp }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);
