import classes from './UserDash.module.css'
import img1 from '../asset/dashboard.png'
import arrow from '../asset/arrow.png'
import Navbar from '../Navbar/Navbar'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import axios from 'axios'
import { Link } from 'react-router-dom'
import Popup from '../Popup/Popup'

import img2 from '../asset/dashboard.jpg'

const UserDash = () => {
    const [dummy, setDummy] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['batli']);
    const [tim, setTim] = useState(0);
    const [data, setData] = useState();
    const [data2, setData2] = useState([]);
    const [del, setDel] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [alength, setAlength] = useState('')

    const USER_TYPE = cookies.USER_TYPE;
    //console.log(USER_TYPE)

    axios.defaults.withCredentials = true;

    const getData = async () => {
        const id = cookies.token2
        try {
            const res = await axios.get('https://primeedgeproperties.in/userDashboard', { params: { id } });

            console.log(res.data.length, "1")
            setData(res.data[0])
            console.log(res, "PO")
        } catch (err) {
            console.log(err)
        }
    }
    const getAgentData = async () => {
        const id = cookies.token2;
        console.log(id, "agentID")
        try {
            const res = await axios.get('https://primeedgeproperties.in/userDashboard2', { params: { id } });

            console.log(res.data.length, "AGENT_LENGTH")
            setAlength(res.data.length);
            console.log(res.data, "dataAgent")
            setData2(res.data)
        } catch (err) {
            console.log(err)
        }
    }

    const deleteHnd = async (e) => {

        e.preventDefault()
        const id = cookies.token2
        console.log('del')

        try {
            const res = await axios.delete('https://primeedgeproperties.in/userDeleteProperty/' + id)
            console.log(res.data)
        } catch (err) {
            console.log(err)
        }

        setDel(false)
        window.location.reload()

    }


    const alertHnd = () => {
        setDel(true);
    }
    // del agent property
    const Agent_alertHnd = async (propId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete?');
        if (isConfirmed) {
            try {
                // Send PROP_ID to the backend
                const res = await axios.delete(`https://primeedgeproperties.in/AgentDeleteProperty/${propId}`);
                console.log(res, "LLPP");
                setDummy(!dummy)
                //setDel0(res.data)
                // Reload the page after successful deletion
                //window.location.reload();
            } catch (err) {
                console.error(err);
            }
        }
        // If cancel is clicked, do nothing
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const alertHnd2 = () => {
        setDel(false)
    }
    const agentDelCancel = () => {
        removeCookie('DEL')
        setDel(false)
    }

    //agent Edit Property
    const agentEdit = (PROP_ID) => {
        setCookie("PROPID", PROP_ID);
        const newTabUrl = 'http://localhost:3000/AgentPropEdit';

        // Open a new tab with the specified URL
        window.open(newTabUrl, '_blank');
    }

    useEffect(() => {
        getData();
        getAgentData();
        setTimeout(() => { setTim(1) }, 400)
    }, [tim, dummy])

    const mtBtnHnd = async () => {
        try {
            const formdata3 = new FormData()
            formdata3.append('abc', '7262020269')

            const res = await axios.post('https://primeedgeproperties.in/myBtn', formdata3)
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <>
            {USER_TYPE === "Owner" && <div>
                {/*<Navbar name={name} />*/}
                <div className={classes.expertHoldMain}>
                    <div className={classes.expertHold}>
                        <img src={img1} className={classes.img1}></img>
                        <p className={classes.exp}>Let our Experts help you with Free Consultation</p>

                    </div>

                    <div className={classes.expertHold2}>
                        <p className={classes.advice}>
                            <img src={arrow} className={classes.arrow}></img>
                            How to increase visibility of your property</p>

                        <p className={classes.advice}>
                            <img src={arrow} className={classes.arrow}></img>
                            Know how your competitors are performing better</p>

                        <p className={classes.advice}>
                            <img src={arrow} className={classes.arrow}></img>
                            Learn on active seekers on your locality</p>
                    </div>

                    <p className={classes.contact}>Contact us:
                        <span className={classes.contact2}>9999999999</span></p>
                </div>


                <p className={classes.listing}>My Listings</p>
                <p className={classes.listing2}>User can post <span className={classes.high}>only one</span> property for free!</p>




                {data && <div className={classes.listHoldContain}>
                    < div className={classes.listHold}>
                        <div className={classes.btnHold}>
                            <Link to='/userEdit'><button className={classes.btn}><i class="fa-regular fa-pen-to-square"></i></button></Link>
                            <button onClick={alertHnd} className={classes.btn}><i class="fa-regular fa-trash-can"></i></button>
                        </div>
                        {<p className={classes.li}>{data.BHK} flat for {data.CUST_PURP} in {data.CITY}</p>}
                        {<p className={classes.li}>Price: Rs. {data.PRICE} | Area: {data.SQFT} sqft</p>}
                        {data.STATUS == 1 && <p className={classes.li} > status: <span className={classes.active}>Active</span></p>}
                        {data.STATUS == 0 && <p className={classes.li} > status: <span className={classes.inActive}>Property will be active post verification. You will get a call for verification</span></p>}

                    </div>
                </div >}

                {!data && <div className={classes.emptyHold}>
                    <img src={img2} className={classes.emptyImg}></img>
                    <p className={classes.emptyTxt1}>You haven't post any property yet!</p>
                    <Link className={classes.btnPostNew} to="/sell_rent_property_page2">Click Here to Post Your Property</Link>

                </div>}
                {isPopupOpen && <Popup onClose={closePopup} txt1='Confirm Delete' txt2='This Action will permanently delete your posting' />}

                {del &&
                    <div className={classes.alert}>
                        <p className={classes.alertTxt}>This action will permanently delete your property</p>
                        <p className={classes.alertTxt}>Press 'OK' to continue</p>
                        <button onClick={deleteHnd} className={classes.alertBtn}>OK</button>
                        <button onClick={alertHnd2} className={classes.alertBtn}>Cancel</button>
                    </div>}




            </div >}


            {USER_TYPE === "Agent" && <div>
                {/*<Navbar name={name} />*/}
                <div className={classes.expertHoldMain}>
                    <div className={classes.expertHold}>
                        <img src={img1} className={classes.img1}></img>
                        <p className={classes.exp}>Let our Experts help you with Free Consultation</p>

                    </div>

                    <div className={classes.expertHold2}>
                        <p className={classes.advice}>
                            <img src={arrow} className={classes.arrow}></img>
                            How to increase visibility of your property</p>

                        <p className={classes.advice}>
                            <img src={arrow} className={classes.arrow}></img>
                            Know how your competitors are performing better</p>

                        <p className={classes.advice}>
                            <img src={arrow} className={classes.arrow}></img>
                            Learn on active seekers on your locality</p>
                    </div>

                    <p className={classes.contact}>Contact us:
                        <span className={classes.contact2}>9999999999</span></p>
                </div>


                <p className={classes.listing}>My Listings</p>
                <p className={classes.listing2}>Agent can post <span className={classes.high}>only five</span> property for free!</p>




                {data2 && data2.map((item, index) => (
                    <div className={classes.listHoldContain} key={index}>
                        < div className={classes.listHold}>
                            <div className={classes.btnHold}>
                                <button onClick={() => agentEdit(item.PROP_ID)} className={classes.btn}><i class="fa-regular fa-pen-to-square"></i></button>
                                <button onClick={() => Agent_alertHnd(item.PROP_ID)} className={classes.btn}><i class="fa-regular fa-trash-can"></i></button>
                            </div>
                            {<p className={classes.li}>{item.BHK} flat for {item.CUST_PURP}  in {item.CITY}</p>}
                            {<p className={classes.li}>Price: Rs. {item.PRICE} | Area: {item.SQFT} sqft</p>}
                            {item.STATUS == 1 && <p className={classes.li} > status: <span className={classes.active}>Active</span></p>}
                            {item.STATUS == 0 && <p className={classes.li} > status: <span className={classes.inActive}>Property will be active post verification. You will get a call for verification</span></p>}

                        </div>
                    </div >
                ))}

                {alength === 0 && <div className={classes.emptyHold}>
                    <img src={img2} className={classes.emptyImg}></img>
                    <p className={classes.emptyTxt1}>You haven't post any property yet!</p>
                    <Link className={classes.btnPostNew} to="/agentPost">Click Here to Post Your Property</Link>

                </div>}

                {alength > 0 && alength < 5 && <div className={classes.emptyHold}>
                    <p className={classes.emptyTxt1}>You post {alength} property yet!</p>
                    <Link className={classes.btnPostNew} to="/agentPost">Click Here to Post Your Property</Link>
                </div>}

                {isPopupOpen && <Popup onClose={closePopup} txt1='Confirm Delete' txt2='This Action will permanently delete your posting' />}

                {del &&
                    <div className={classes.alert}>
                        <p className={classes.alertTxt}>This action will permanently delete your property</p>
                        <p className={classes.alertTxt}>Press 'OK' to continue</p>
                        <button className={classes.alertBtn}>OK</button>
                        <button onClick={agentDelCancel} className={classes.alertBtn}>Cancel</button>
                    </div>}




            </div >}
        </>
    )
}

export default UserDash;