import About1 from "../ABOUT/About1";
import Mission from "../ABOUT/Mission";
import Footer from "../Footer/Footer";

const About = () => {
    return (
        <>
            <About1 />
            <Mission />

        </>
    );
};
export default About;