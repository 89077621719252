import classes from "./About1.module.css";
import Navbar from "../Navbar/Navbar";
import image1 from "./Images/2150760990.jpg";
import image2 from "./Images/2150849049.jpg";

const About1 = () => {
    return (
        <>

            <div className={classes.header}>
                <h1>About Prime Edge Properties</h1>
            </div>
            <div className={classes.start}>
                <div className={classes.left}>
                    <h2>Every Home has a Story.<br />Here's Ours</h2>
                    <p>Prime Edge Properties was established in 1996 and has been servicing the local area since then. Prime Edge Properties is a family operated business and we understand the worth of your family home or investment and what a big deal real estate can be. We believe that when it comes to finding a Property there are very important points which must be taken into account. That's why we go beyond typical listings by sourcing information directly from residents and offering multiple options based on their needs to provide people with deeper service about their future experience. We are committed to helping you discover a place where you will love living and where you will feel more connected to the experiences you have been searching for.</p>
                    <br /><p>Our team is made up of experienced veterans and new generation agents and we value this because it gives us comprehensive knowledge of the industry, where it’s going, how it’s quickly changing and what we need to do as a team to market your property to buyers or tenants.</p>
                    <br /><p>Our user-friendly interface empowers you to explore properties with ease, narrowing down options based on your preferences. Discover your dream property faster than ever before. Start your search today and unlock a world of possibilities with Prime Edge Properties</p>
                </div>
                <div className={classes.right}>
                    <img className={classes.image1} src={image1} alt="best real estate consultancy" />
                    <img className={classes.image2} src={image2} alt="best real estate consultancy" />
                </div>
            </div>
        </>
    );
};
export default About1;