import classes from "./ContactForm.module.css";
import Navbar from "../Navbar/Navbar";
import image from "./Images/6159289.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AdminPopup from "../ADMIN/CREATE/AdminPopup";
import { useCookies } from 'react-cookie';

const ContactForm = () => {
    const [purpose, setPurpose] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies(['detail']);
    const [data, setData] = useState({
        name: '',
        email: '',
        mobile: ''
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    const handlebtn1 = () => {
        setPurpose('Buying')
    }
    const handlebtn2 = () => {
        setPurpose('Selling')
    }
    const handlebtn3 = () => {
        setPurpose('Rent/Lease')
    }

    // after submit popup

    const [already, setAlready] = useState(false);
    const [myload, setMyload] = useState(false);
    const [alert, setAlert] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const closePopup = () => {
        setIsPopupOpen(false)
        setAlready(false)
        setMyload(false)
        navigate("/")
    }

    //console.log(purpose)
    const navigate = useNavigate();

    const ContactSubmit = async (e) => {
        e.preventDefault();
        let projName = cookies.NameOfProperty
        console.log(projName, "DD")
        //console.log(data)
        //console.log(purpose)
        setMyload(true)
        const response = await axios.post('https://primeedgeproperties.in/enquiryForm', { data, purpose, projName })
            .then(res => {
                setMyload(false);
                setIsPopupOpen(true)
                removeCookie('NameOfProperty')

            })
            .catch(err => console.log(err))
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <section className={classes.container}>
                <div className={classes.card}>
                    <div className={classes.left}>
                        <div className={classes.form_wrapper}>
                            <p className={classes.h}><i class="fa-solid fa-phone"></i> Contact our Real Estate Experts</p>
                            <form className={classes.form_container} onSubmit={ContactSubmit}>
                                <input className={classes.inp} type='text' onChange={handleChange} name="name" placeholder="Name" />
                                <input className={classes.inp} type='email' onChange={handleChange} name="email" placeholder="Email ID" />
                                <input className={classes.inp} type='tel' onChange={handleChange} name="mobile" placeholder="Phone Number" />
                                <p className={classes.bold}>Interested in</p>
                                <div className={classes.fill_row}>
                                    <button type="button" onClick={handlebtn1} className={`${purpose === 'Buying' ? classes.button1 : classes.button2}`}>Buying</button>
                                    <button type="button" onClick={handlebtn2} className={`${purpose === 'Selling' ? classes.button1 : classes.button2}`} >Selling</button>
                                    <button type="button" onClick={handlebtn3} className={`${purpose === 'Rent/Lease' ? classes.button1 : classes.button2}`} >Rent/Lease</button>
                                </div>
                                <button type="submit" className={classes.sub_mit}>Contact Now</button>
                            </form>
                        </div>
                    </div>
                    <div className={classes.right}>
                        <img src={image} alt="Contact Us" />
                    </div>
                </div>
                {isPopupOpen && <AdminPopup onClose={closePopup} txt1="Form Submited Succesfully"></AdminPopup>}
                {myload && <AdminPopup onClose={closePopup} txt1="Form Submiting .." txt2='Do not Press Back / Refresh '></AdminPopup>}
            </section>
        </>
    );
};
export default ContactForm;