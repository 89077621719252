import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import classes from "./ClientDetails.module.css";
import star from "../HOME/Projects/Images/5star.png";
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';

const ClientDetails = () => {
    const [details, setDetails] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    const [cookies, setCookie, removeCookie] = useCookies(['navD']);

    const Property_name_Cookie = (propertyname) => {
        setCookie('NameOfProperty', propertyname, { path: '/' })
    }

    const getDetail = async (SIGNUP_ID) => {
        //console.log(SIGNUP_ID)
        try {
            const res = await axios.get('https://primeedgeproperties.in/Sell-Rent/Details', { params: { SIGNUP_ID } })
            console.log(res.data)
            setDetails(res.data)
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        let SIGNUP_ID = cookies.navD;
        getDetail(SIGNUP_ID)
    }, []);
    return (
        <>

            {details && details.map((item) => (
                <section className={classes.container}>
                    <div className={classes.top1}>
                        <div className={classes.left}>
                            <div className={classes.leftTop}>
                                {selectedImage && (
                                    <div className={classes.modal} onClick={() => setSelectedImage(null)}>
                                        <img src={selectedImage} alt="Selected Cover" />
                                    </div>
                                )}
                                {!selectedImage && (
                                    <div className={classes.modal}>
                                        <img src={`https://primeedgeproperties.in/BUILDER/${item.COVER_IMAGE.split(',')[0]}`} alt="Default Cover" />
                                    </div>
                                )}
                            </div>
                            <div className={classes.coverImages}>
                                {item.COVER_IMAGE.split(',').map((cover, index) => (
                                    <img
                                        key={index}
                                        src={`https://primeedgeproperties.in/BUILDER/${cover}`}
                                        alt={`Cover ${index}`}
                                        onClick={() => setSelectedImage(`https://primeedgeproperties.in/BUILDER/${cover}`)}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className={classes.right}>
                            <h1>{item.PROPERTY_NAME}</h1>
                            <p>{item.LOCALITY} , {item.CITY}</p>
                            <h1>₹ {item.PRICE}</h1>
                            <img className={classes.star} src={star} alt="5 star rating" />
                            <p>Status: <b>{item.POSSESSION_STATUS}</b></p>
                            <p><i class="fa-solid fa-bed"></i> {item.BHK}</p>
                            <p><i class="fa-solid fa-couch"></i> {item.FURNISHING}</p>
                            <p><i class="fa-solid fa-bath"></i> {item.FURNISHING} Bathroom</p>
                            <p><i class="fa-solid fa-house"></i> {item.SQFT} Sq.Ft. (Carpet Area)</p>
                            <p><i class="fa-solid fa-house"></i> Available From: {item.AVAILABLE_FROM ? item.AVAILABLE_FROM : "----"}</p>
                            <Link onClick={() => { Property_name_Cookie(item.PROPERTY_NAME) }} to="/contactus" target="_blank" className={classes.CallBTN}>Request a Call Back</Link>
                        </div>
                    </div>
                    <div className={classes.top2}>
                        <div className={classes.left}>
                            <div className={classes.card}>
                                <h3>Property Information</h3>
                                <div className={classes.underline}></div>
                                <div className={classes.row}>
                                    <div className={classes.column}>
                                        <p>Listing Type</p>
                                        <p><b>{item.CUST_PURP}</b></p>
                                    </div>
                                    <div className={classes.column}>
                                        <p>Property Type</p>
                                        <p><b>{item.PROPERTY_TYPE}</b></p>
                                    </div>
                                    <div className={classes.column}>
                                        <p>Building Type</p>
                                        <p><b>{item.BUILDING_TYPE}</b></p>
                                    </div>
                                </div>
                                <div className={classes.row}>
                                    <div className={classes.column}>
                                        <p>City</p>
                                        <p><b>{item.CITY}</b></p>
                                    </div>
                                    <div className={classes.column}>
                                        <p>Locality</p>
                                        <p><b>{item.LOCALITY}</b></p>
                                    </div>
                                    <div className={classes.column}>
                                        <p>Area</p>
                                        <p><b>{item.SQFT} Sq.Ft. (Carpet Area)</b></p>
                                    </div>
                                </div>
                                <div className={classes.row}>
                                    <div className={classes.column}>
                                        <p>Price</p>
                                        <p><b>{item.PRICE}</b></p>
                                    </div>
                                    <div className={classes.column}>
                                        <p>Possession Status</p>
                                        <p><b>{item.POSSESSION_STATUS}</b></p>
                                    </div>
                                    <div className={classes.column}>
                                        <p>Furnishing Status</p>
                                        <p><b>{item.FURNISHING}</b></p>
                                    </div>
                                </div>
                                <div className={classes.row}>
                                    <div className={classes.column}>
                                        <p>Number of Bhk</p>
                                        <p><b>{item.BHK}</b></p>
                                    </div>
                                    <div className={classes.column}>
                                        <p>Number of Bathroom</p>
                                        <p><b>{item.BATHROOM}</b></p>
                                    </div>
                                    <div className={classes.column}>
                                        <p>Covered Parking</p>
                                        <p><b>{item.PARKING}</b></p>
                                    </div>
                                </div>
                                <div className={classes.row}>
                                    <div className={classes.column}>
                                        <p>Floor Number</p>
                                        <p><b>{item.FLOOR_NO}</b></p>
                                    </div>
                                    <div className={classes.column}>
                                        <p>Total Floor Count</p>
                                        <p><b>{item.TOTAL_FLOOR}</b></p>
                                    </div>
                                    <div className={classes.column}>
                                        <p>Age of Property (Years)</p>
                                        <p><b>{item.AGE_OF_PROPERTY}</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.right}></div>
                    </div>
                </section>
            ))}

        </>
    );
};
export default ClientDetails;