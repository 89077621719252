import classes from "./Oneplace.module.css";
import image1 from "./Images/home-loan-mobile.svg";

const Oneplace = () => {
    return(
        <div className={classes.container}>
            <h2>Everything you Need at One Place</h2>
            <div className={classes.container2}>
                
                <div className={classes.card}>
                    <p className={classes.head}>Home Loan</p>
                    <p>Get instant sanction on your home loan in 5 minutes</p>
                    <div className={classes.shape}>
                        <img src={image1} alt="instant sanction on your home loan" />
                    </div>
                </div>
                <div className={classes.card}>
                    <p className={classes.head}>Home Interior</p>
                    <p>Designs you admire at price you love</p>
                    <div className={classes.shape}>
                        <img src={image1} alt="instant sanction on your home loan" />
                    </div>
                </div>
                <div className={classes.card}>
                    <p className={classes.head}>Property Legal Services</p>
                    <p>End to end legal assistance in property related matters</p>
                    <div className={classes.shape}>
                        <img src={image1} alt="instant sanction on your home loan" />
                    </div>
                </div>
                <div className={classes.card}>
                    <p className={classes.head}>Property Management</p>
                    <p>The simplest way to manage your home</p>
                    <div className={classes.shape}>
                        <img src={image1} alt="instant sanction on your home loan" />
                    </div>
                </div>
                <div className={classes.card}>
                    <p className={classes.head}>Escrow Services</p>
                    <p>Transact Smarter & Safer. Protect your deals from frauds</p>
                    <div className={classes.shape}>
                        <img src={image1} alt="instant sanction on your home loan" />
                    </div>
                </div>
                <div className={classes.card}>
                    <p className={classes.head}>Online Rent Agreement</p>
                    <p>Get an e-stamped rent agreement in your mail instantly</p>
                    <div className={classes.shape}>
                        <img src={image1} alt="instant sanction on your home loan" />
                    </div>
                </div>
                <div className={classes.card}>
                    <p className={classes.head}>Sell or Rent Property</p>
                    <p>List property to sell or rent online for free</p>
                    <div className={classes.shape}>
                        <img src={image1} alt="instant sanction on your home loan" />
                    </div>
                </div>
                <div className={classes.card}>
                    <p className={classes.head}>Property Registration</p>
                    <p>Effortless Property Registration with end-to-end document handling.</p>
                    <div className={classes.shape}>
                        <img src={image1} alt="instant sanction on your home loan" />
                    </div>
                </div>
                <div className={classes.card}>
                    <p className={classes.head}>Home Loan</p>
                    <p>Get instant sanction on your home loan in 5 minutes</p>
                    <div className={classes.shape}>
                        <img src={image1} alt="instant sanction on your home loan" />
                    </div>
                </div>

            </div>
        </div>
    );
};
export default Oneplace;