import { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from "axios";
import { AppProvider, useAppContext } from "../AppContext";
import LOGO from "./IMAGE/LOGO.png"
import Cookies from 'js-cookie';

const Navbar = () => {
    const [burger, setBurger] = useState(false);
    const { isSignUp, toggleSignUp } = useAppContext();
    const [cookies, setCookie, removeCookie] = useCookies(['batli']);

    // navbar login name Cookie
    const loginSuccess = Cookies.get('loginSuccessful')
    //admin status Cookie
    const adminSuccess = Cookies.get('adminStatus')

    const USER_TYPE = cookies.USER_TYPE;



    const navigate = useNavigate();

    const logBtnHnd = async () => {
        if (loginSuccess) {
            try {
                const res = await axios.post('https://primeedgeproperties.in/logout');
                //console.log(res)
                if (res.data) {
                    Cookies.remove('loginSuccessful')
                    Cookies.remove('adminStatus')
                    Cookies.remove("USER_TYPE")
                    Cookies.remove("token2")
                    Cookies.remove("PROPID")
                    navigate('/')
                    window.location.reload()
                } else { console.log('err') }


            } catch (err) {
                console.log(err)
            }
        } else {

            navigate('/login')

        }

    }

    const sellHnd = () => {
        if (cookies.loginSuccessful) {
            if (USER_TYPE === "Owner") {
                navigate('/sell_rent_property_page2');
            } else if (USER_TYPE === "Agent") {
                navigate('/agentPost');
            }
        } else {
            navigate('/login');
        }
    }




    function show() {
        setBurger(!burger);
    }
    const [display, setDisplay] = useState();
    function dis() {
        setDisplay(!display)
    }





    const menuRef = useRef(null);
    const imgRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Check if the click is outside the menuRef
            if ((menuRef.current && !menuRef.current.contains(event.target)) && (imgRef.current && !imgRef.current.contains(event.target))) {
                setDisplay(false);
            }
        };

        // Attach the event listener to the document
        document.addEventListener('mousedown', handleOutsideClick);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);



    return (
        <AppProvider>
            <nav className="navbar">
                <div className="logo">
                    <img src={LOGO} />
                </div>

                <div className="navlinks">
                    <li className="navitem" >
                        <div class="dropdown">
                            <button class="dropbtn">Sell <i className="fa-solid fa-angle-down"></i></button>
                            <div class="dropdown-content">
                                <Link to="/navsell">Property For Sell</Link>
                            </div>
                        </div>
                    </li>
                    <li className="navitem" >
                        <div class="dropdown">
                            <button class="dropbtn">Rent <i className="fa-solid fa-angle-down"></i></button>
                            <div class="dropdown-content">
                                <Link to="/propToRent">Property For Rent</Link>
                            </div>
                        </div>
                    </li>
                    <li className="navitem" >
                        <div class="dropdown">
                            <button class="dropbtn">Projects <i className="fa-solid fa-angle-down"></i></button>
                            <div class="dropdown-content">
                                <Link to="/navallproject">Project listing in Pune</Link>
                            </div>
                        </div>
                    </li>

                </div>

                <div className="btn">

                    {loginSuccess && <div class="dropdown">
                        <p class="dropdownContent">Hello {loginSuccess} <i className="fa-solid fa-angle-down"></i></p>
                        <div class="dropdown-content">
                            {adminSuccess !== 'true' && <Link to='/mydashboard'>My Dashboard</Link>}
                            <button className="login2" onClick={logBtnHnd} >{loginSuccess ? 'Logout' : 'Login'}</button>

                        </div>
                    </div>}

                    <button onClick={sellHnd} className="sell">Sell/Rent Property</button>
                    {!loginSuccess && <button className="login" onClick={logBtnHnd} >{loginSuccess ? 'Logout' : 'Login'}</button>}






                    <button className="hammer" onClick={dis}><i className={burger ? "fas fa-times" : "fas fa-bars"} ref={imgRef} onClick={show}></i></button>
                </div>
                <div className={display ? "burgerdata" : "burgerdata2"} ref={menuRef}>
                    <li className="burgerItem"><Link className="burgerLink" to="/">Home</Link></li>
                    <li className="burgerItem"><Link className="burgerLink" to="/about">About Us</Link></li>
                    <li className="burgerItem"><Link className="burgerLink" to="/contactus">Contact Us</Link></li>
                    <li className="burgerItem">Rent</li>
                    <li className="burgerItem">Projects</li>
                </div>



            </nav>
        </AppProvider>
    );
};
export default Navbar