import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/PAGES/Home';
import About from './Components/PAGES/About';
import All from "./Components/ADMIN/ALLINONE/All";
import Create from './Components/ADMIN/CREATE/Create';
import Dashboard from './Components/ADMIN/DASHBOARD/Dashboard';
import ProjectDetail from './Components/HOME/Projects/ProjectDetail';
import SellRent from './Components/SELLRENT/SellRent';
import SellRent2 from './Components/SELLRENT/SellRent2';
import Signup from './Components/SIGNUP/Signup';
import Login from './Components/SIGNUP/Login';
import Client_side_request from './Components/ADMIN/CLIENT_SIDE_PROJECT/Client_side_request';
import Create2 from './Components/SELLRENT/Create2';
import ProjectBhk from './Components/HOME/Projects/ProjectBhk';
import Testing from './Components/ADMIN/CREATE/Testing'
import { AppProvider } from './Components/AppContext';
import UserDash from './Components/user/UserDash';
import EditPage from './Components/ADMIN/ALLINONE/EditPage';
import ProjDeails from './Components/HOME/Projects/ProjDeails';
import UserEdit from './Components/user/UserEdit';
import CreateUserProp from './Components/ADMIN/CREATE/CreateUserProp';
import Sellpage from './Components/NavPages/Sellpage';
import Rentpage from './Components/NavPages/Rentpage';
import Projectpage from './Components/NavPages/Projectpage';
import ContactForm from './Components/CONTACT/ContactForm';
import ClientDetails from './Components/NavPages/ClientDetails';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import SearchDetails from './Components/HOME/SearchDetails/SearchDetails';
import AdminClientEdit from './Components/ADMIN/CLIENT_SIDE_PROJECT/AdminClientEdit';
import Enquiry from './Components/ADMIN/ENQUIRY/Enquiry'


import { Cookies, useCookies } from 'react-cookie';
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import EditPage2 from './Components/ADMIN/ALLINONE/EditPage2';
import Search2 from './Components/HOME/Hero/Search2';
import AllReports from './Components/ADMIN/REPORTS/AllReports';
import UserReport from './Components/ADMIN/REPORTS/UserReport';
import AgentPost from './Components/AGENT/AgentPost';
import AgentEdit from './Components/user/AgentEdit';
import AdminAgentEdit from './Components/ADMIN/CLIENT_SIDE_PROJECT/AdminAgentEdit';
import AccountInfo from './Components/ADMIN/REPORTS/AccountInfo';






function App() {
  const [name, setName] = useState();
  const [name2, setName2] = useState();
  // const navigate = useNavigate()
  useEffect(() => {
    axios.get('http://localhost:8800/appp')
      .then((res) => {
        if (res.data.valid) {
          setName(res.data.username)
          localStorage.setItem('name', res.data.username)
          setName2(localStorage.getItem('name'))

          //console.log(name)
          //setCookie('navN', res.data.username)
        } else {
          //navigate('/')
          console.log('else')
        }
      })
      .catch(err => {
        console.log(err)
      })

    //setTimeout(() => { setTim(1) }, 400);
  }, [name])

  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  return (
    <AppProvider>
      <div className="App">
        <BrowserRouter>
          <Navbar name={name2}></Navbar>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/sell_rent_property" element={<SellRent />} />
            <Route path="/sell_rent_property_page2" element={<SellRent2 />} />
            <Route path="/projectD/:ID" element={<ProjectDetail />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/create" element={<Create />} />
            <Route path="/all_project_details" element={<All />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/client_side_request" element={<Client_side_request />} />
            <Route path='/page' element={<Create2 />} />
            <Route path='/projectD_bhk/:ID' element={<ProjectBhk />} />
            <Route path='/testing' element={<Testing />} />
            <Route path='/mydashboard' element={<UserDash />} />
            <Route path='/editPage' element={<EditPage2 />} />
            <Route path='/propDetails' element={<ProjDeails />} />
            <Route path='/userEdit' element={<UserEdit />} />
            <Route path='/postUserProp' element={<CreateUserProp />} />
            <Route path='/navsell' element={<Sellpage />} />
            <Route path='/propToRent' element={<Rentpage />} />
            <Route path='/navallproject' element={<Projectpage />} />
            <Route path='/contactus' element={<ContactForm />} />
            <Route path='/Sell-Rent/Details' element={<ClientDetails />} />
            <Route path='/searchdetailspage' element={<SearchDetails />} />
            <Route path='/adUserEdit' element={<AdminClientEdit />} />
            <Route path='/viewAllEnquiry' element={<Enquiry />} />
            <Route path='/search2' element={<Search2 />} />
            <Route path='/reports' element={<AllReports />} />
            <Route path='/userReport' element={<UserReport />} />
            <Route path='/agentPost' element={<AgentPost />} />
            <Route path='/AgentPropEdit' element={<AgentEdit />} />
            <Route path='/adAgentEdit' element={<AdminAgentEdit />} />
            <Route path="/accountReport" element={<AccountInfo />} />

          </Routes>
          <Footer name={name2}></Footer>
        </BrowserRouter>
      </div>
    </AppProvider>
  );
}

export default App;
