import classes from "./Hero.module.css";
import buy from "./Images/buy.png";
import rent from "./Images/house.png";
import project from "./Images/construction.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from 'react-cookie';


function Hero() {

    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(['searchs']);

    // Assuming results is an array of objects with a "matched_column" property
    const uniqueResults = results.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t.matched_column === item.matched_column
        ))
    );

    const navigate = useNavigate();
    const handleResultClick = (item, results) => {
        setCookie("srch", item.matched_column || results[0].matched_column)
        navigate("/search2")
    };

    useEffect(() => {
        const handleSearch = async () => {
            try {
                // If query is empty, fetch all results
                const searchQuery = query.trim() === '' ? 'all' : query;
                const response = await axios.get(`https://primeedgeproperties.in/search?query=${searchQuery}`);
                setResults(response.data);
                //console.log(response.data);
            } catch (error) {
                console.error('Error searching:', error);
            }
        };

        // Trigger search when 'query' changes
        handleSearch();
    }, [query]);
    return (
        <div className={classes.container}>
            <h1>Prime Edge Properties- List once, service for life!</h1>
            <div className={classes.r2}>
                <Link to="/navsell" className={classes.box}>
                    <img src={buy} alt="best property seller" />
                    <p>Buy Properties</p>
                </Link>
                <Link to="/navallproject" className={classes.box}>
                    <img src={project} alt="best property seller" />
                    <p>New Projects</p>
                </Link>
                <Link to="/propToRent" className={classes.box}>
                    <img src={rent} alt="best property seller" />
                    <p>Rent Properties</p>
                </Link>
            </div>
            <div className={classes.r3}>
                <input
                    type="text"
                    placeholder="Search Property Name ..."

                    onChange={(e) => setQuery(e.target.value)}
                />
                {results.length > 0 && <div className={classes.search_result}>
                    { /*{results.map((item)=>(
                        <div>{item.matched_column}</div>
                    ))}*/}
                    <div className={classes.result_dropdown}>
                        <p onClick={() => handleResultClick(results[0])}>{results?.[0]?.matched_column}<span className={classes.list}>{results?.length}+ Listing</span></p>
                        {uniqueResults.map((item, index) => (
                            <p key={index} onClick={() => handleResultClick(item)}>{item.matched_column}</p>
                        ))}
                    </div>
                </div>}
            </div>

        </div>
    );
};
export default Hero;