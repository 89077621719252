import axios from "axios";
import classes from "./UserReport.module.css";
import { useState } from "react";

const UserReport = () => {
    const today = new Date().toISOString().split('T')[0];
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [report, setReport] = useState("");

    const handleStartDateChange = (event) => {
        const selectedStartDate = event.target.value;
        if (selectedStartDate > today) {
            alert("Start date cannot be a future date.");
            return;
        }
        setStartDate(selectedStartDate);
        if (selectedStartDate > endDate) {
            setEndDate(selectedStartDate);
        }
    };

    const handleEndDateChange = (event) => {
        const selectedEndDate = event.target.value;
        if (selectedEndDate > today) {
            alert("End date cannot be a future date.");
            return;
        }
        if (selectedEndDate < startDate) {
            alert("End date cannot be less than the start date.");
            return;
        }
        setEndDate(selectedEndDate);
    };

    const handleSearch = async (e) => {
        e.preventDefault();

        try {
            const res = await axios.post('https://primeedgeproperties.in/UserReportSearch', { startDate, endDate })
            setReport(res.data)
            console.log(res.data, 'RES')
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.top1}>
                <div>
                    <label htmlFor="start">Start Date : </label>
                    <input type="date" id="start" name="start" max="9999-12-31" value={startDate} onChange={handleStartDateChange} />
                </div>
                <div>
                    <label htmlFor="end">End Date : </label>
                    <input type="date" id="end" name="end" max="" value={endDate} onChange={handleEndDateChange} />
                </div>
                <div className={classes.search}>
                    <p onClick={handleSearch}>Search <i className="fa-solid fa-magnifying-glass"></i></p>
                </div>
            </div>

            <div className={classes.searchResult}>
                <table>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>User</th>
                        <th>Date</th>
                    </tr>
                    {report && report.map((item) => (
                        <tr>
                            <td>{item.NAME}</td>
                            <td>{item.EMAIL}</td>
                            <td>{item.MOBILE}</td>
                            <td>{item.USER_TYPE}</td>
                            <td>{formatDateTime(item.REG_DATE)}</td>
                        </tr>
                    ))}

                </table>
            </div>
        </div>
    )
};

// Function to format date and time
const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
};
export default UserReport;