import classes from "./Search2.module.css"
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useNavigate } from "react-router-dom";
const Search2 = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['srch']);
    const [result, setResult] = useState('');

    const Property_name_Cookie = (propertyname) => {
        setCookie('NameOfProperty', propertyname, { path: '/' })
    }

    const show = () => {
        const cook = cookies.srch;
        console.log(cook)
    }
    const getAll = async () => {
        const cook = cookies.srch;
        const res = await axios.get('https://primeedgeproperties.in/search2Cards', { params: { cook } })
        console.log(res)
        setResult(res.data)
    }
    const navigate = useNavigate();
    const handleDetails = async (id) => {
        //console.log(id)
        try {
            await axios.get("https://primeedgeproperties.in/searchdetails", { params: { id } })
            setCookie('searchs', id)
            navigate('/searchdetailspage')
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {

        getAll()
    }, []);
    return (
        <div className={classes.container}>
            <div className={classes.left}>
                {result && result.map((item, index) => (
                    <div className={classes.card} key={index}>
                        <div className={classes.Card_top}>
                            <div className={classes.card_left}>
                                {item.THUMBNAIL ? <img src={`https://primeedgeproperties.in/BUILDER/` + item.THUMBNAIL} /> : <img src={`https://primeedgeproperties.in/BUILDER/` + item.PROP_IMAGE} />}
                            </div>
                            <div className={classes.card_right}>
                                <p className={classes.Pname}>{item.PROPERTY_NAME || item.PROJECT_NAME}</p>
                                <p className={classes.small_font}>{item.BHK} for {item.CUST_PURP ? item.CUST_PURP : 'Sell'} in {item.LOCALITY} , {item.CITY}</p>
                                <p className={classes.Pname}>₹ {item.PRICE} {item.MIN_PRICE} - {item.MAX_PRICE}</p>
                                <div className={classes.row}>
                                    <div><p className={classes.small_font}>{item.SQFT} {item.MIN_SQFT} - {item.MAX_SQFT} SQFT</p> <p className={classes.small}>Built-up Area</p></div> :
                                    <div><p className={classes.small_font}>{item.POSSESSION_STATUS} {item.POSSESSION}</p> <p className={classes.small}>Possesion Status</p></div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.Card_bottom}>
                            {/*<div><p className={classes.Owner_name}>{item.OWNER_NAME} <p className={classes.name}>{item.CUST_TYPE}</p></p></div>*/}
                            <div><button onClick={() => handleDetails(item.BUILDER_ID || item.SIGNUP_ID)} className={classes.Dbtn}>Details</button> <Link onClick={() => { Property_name_Cookie(item.PROPERTY_NAME || item.PROJECT_NAME) }} to="/contactus" target="_blank" className={classes.Cbtn}>Contact</Link></div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={classes.right}>

            </div>
        </div>
    );
};
export default Search2;
