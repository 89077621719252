import Hero from "../HOME/Hero/Hero";
import Navbar from "../Navbar/Navbar";
import Project from "../HOME/Projects/Project";
import Oneplace from "../HOME/Oneplace/Oneplace";
import Footer from "../Footer/Footer";
//import Search from "../search/Search";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import classes from './Home.module.css';
import { Cookies, useCookies } from 'react-cookie';



const Home = () => {
    const [logg, setLogg] = useState(false);
    const [name, setName] = useState('');
    const [tim, setTim] = useState(0);
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const navigate = useNavigate()
    axios.defaults.withCredentials = true;

    useEffect(() => {
        /*axios.get('https://primeedgeproperties.in/appp')
            .then((res) => {
                if (res.data.valid) {
                    setName(res.data.username)
                    console.log(name)
                } else {
                    navigate('/')
                    console.log('else')
                }
            })
            .catch(err => {
                console.log(err)
            })*/

        //setTimeout(() => { setTim(1) }, 400);
        //window.location.reload();
    }, [])

    const logHnd = () => {
        setLogg(!logg)

    }

    return (
        <>



            <div className={classes.welcome}>
                {/*{cookies.token && <p>welcome {name}</p>}*/}

            </div>
            <Hero />
            <Project />
            <Oneplace />



        </>
    )
}
export default Home;