import { Link } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import classes from "./AllReports.module.css";

const AllReports = () =>{
    return(
        <>
        <Link className={classes.backBtn} to="/dashboard">Back to Dashboard</Link>
        <div className={classes.heading}><h1>View All Reports</h1></div>
        <div className={classes.container}>
            <Link className={classes.btn} to="/userReport">User Reports</Link>
            <Link className={classes.btn} to="/accountReport">Account Report</Link>
            <Link className={classes.btn} to="#">Other Reports</Link>
        </div>
        </>
    );
};
export default AllReports;