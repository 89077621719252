import React from 'react';
import './Popup.css';

const Popup = ({ onClose, txt1, txt2, txt3, AdminDel, onDelete }) => {
    return (
        <div className="popup-container">
            <div className="popup">
                <h2>{txt1}</h2>
                <p>{txt2}</p>

                <div className='popBtnDiv'>
                    {txt3 !== "" && <button className='popBtn2' onClick={onClose}>Close</button>}

                    {AdminDel == '.' && <button className='popBtn' onClick={onDelete}>Delete</button>}

                </div>

            </div>


        </div>
    );
};

export default Popup;