// Import necessary dependencies and modules
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import classes from './SearchDetails.module.css';
import { Link } from "react-router-dom";
import star from "../../HOME/Projects/Images/5star.png";
import imageblg from "../../HOME/Projects/Images/building.png";
import { Button } from "react-scroll";
import Footer from '../../Footer/Footer'
import HTMLFlipBook from "react-pageflip";
//import image from "../Images/bg.jpg"


function SearchDetails() {
    const [details, setDetails] = useState('')
    const [details2, setDetails2] = useState('')
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies(['searchs']);

    const Property_name_Cookie = (propertyname) => {
        setCookie('NameOfProperty', propertyname, { path: '/' })
    }


    const getData = async () => {
        const id = cookies.searchs;
        try {
            const res = await axios.get('https://primeedgeproperties.in/searchdetails', { params: { id } })
            res.data.data2 ? console.log(res.data.data2[0]) : console.log(res)
            res.data.data2 ? setDetails(res.data.data2[0]) : setDetails2(res.data[0])
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {


        getData();
    }, [])
    return (
        <>
            {details && <div className={classes.container}>
                <div className={classes.top1}>
                    <div className={classes.left}>
                        <div className={classes.leftTop}>
                            {selectedImage && (
                                <div className={classes.modal} onClick={() => setSelectedImage(null)}>
                                    <img src={selectedImage} alt="Selected Cover" />
                                </div>
                            )}
                            {!selectedImage && (
                                <div className={classes.modal}>
                                    <img src={`https://primeedgeproperties.in/BUILDER/${details.COVER?.split(',')[0]}`} alt="Default Cover" />
                                </div>
                            )}

                        </div>
                        <div className={classes.coverImages}>
                            {details.COVER?.split(',').map((cover, index) => (
                                <img
                                    key={index}
                                    src={`https://primeedgeproperties.in/BUILDER/${cover}`}
                                    alt={`Cover ${index}`}
                                    onClick={() => setSelectedImage(`https://primeedgeproperties.in/BUILDER/${cover}`)}
                                />
                            ))}

                        </div>
                    </div>
                    <div className={classes.right}>
                        <h1>{details.PROJECT_NAME}</h1>
                        <p>{details.LOCALITY} , {details.CITY}</p>
                        <hr />
                        <h2>₹ {details.MIN_PRICE} - {details.MAX_PRICE}</h2>
                        <img className={classes.star} src={star} alt="5 star rating" />
                        <p>Status : <b>{details.POSSESSION}</b></p>
                        <p>{details.BHK} </p>
                        <p>{details.MIN_SQFT} - {details.MAX_SQFT} SQ.FT (Carpet)</p>

                        <div className={classes.psize}>
                            <div className={classes.logimg}><img src={imageblg} alt="Load" /></div>
                            <div>
                                <p>Project Size :</p>
                                <p>{details.TOTAL_UNITS} units ✦  {details.TOTAL_ACRES} Acres</p>
                            </div>
                        </div>

                        <button className={classes.btn}><Link onClick={() => Property_name_Cookie(details.PROJECT_NAME)} className={classes.btn} to="/contactus">Request a Call Back</Link></button>
                    </div>
                </div>
                <div className={classes.tabs}>
                    <header className={classes.nav}>
                        <nav className={classes.nav__container__actions}>
                            <ul>
                                <li>
                                    <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="overview">
                                        Overview
                                    </Button>
                                </li>
                                <li>
                                    <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="regulatoryInfo">
                                        Regulatory Information
                                    </Button>
                                </li>
                                <li>
                                    <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="brochure">
                                        Brochure
                                    </Button>
                                </li>
                                <li>
                                    <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="video">
                                        Video
                                    </Button>
                                </li>
                                <li>
                                    <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="amenities">
                                        Amenities
                                    </Button>
                                </li>
                                <li>
                                    <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="specifications">
                                        Specifications
                                    </Button>
                                </li>
                                <li>
                                    <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="propertyInfo">
                                        Property Information
                                    </Button>
                                </li>
                                <li>
                                    <Button className={classes.navbtn} activeStyle={{ color: 'rgb(132, 132, 237)' }} smooth spy offset={-120} to="priceList">
                                        Price List
                                    </Button>
                                </li>
                            </ul>
                        </nav>
                    </header>
                    <section className={classes.card} id="overview">
                        <h3>{details.PROJECT_NAME} - Project Info</h3>
                        <div className={classes.underline}></div>
                        <p>{details.OVERVIEW}</p>
                    </section>

                    <section className={classes.card2} id="regulatoryInfo">
                        <h3>{details.PROJECT_NAME} - Regulatory Information</h3>
                        <div className={classes.underline}></div>
                        <div className={classes.rer}><h4>Project RERA Reg.</h4><p>{details.RERA}</p></div>
                    </section>
                    <section className={classes.card2} id="brochure">
                        <h3>{details.PROJECT_NAME} Brochure</h3>
                        <div className={classes.underline}></div>
                        <div className={classes.brochureContainer}>
                            <HTMLFlipBook className={classes.innerBrochureContainer}
                                width={300}
                                height={200}
                            >
                                <div className={classes.coverPage}></div>
                                {details.BROCHURE?.split(',').map((cover, index) => (
                                    <img className={classes.broucher} key={index} src={`https://primeedgeproperties.in/BUILDER/${cover}`} alt={`Cover ${index}`} />
                                ))}
                            </HTMLFlipBook>
                        </div>
                    </section>
                    <section className={classes.card2} id="video">
                        <h3>{details.PROJECT_NAME} Video</h3>
                        <div className={classes.underline}></div>
                        <video className={classes.vid} width="660" controls >
                            <source src={`https://primeedgeproperties.in/BUILDER/` + details.VIDEO} type="video/mp4" />
                        </video>
                    </section>
                    <section className={classes.card2} id="amenities">
                        <h3>{details.PROJECT_NAME} - Amenities</h3>
                        <div className={classes.underline}></div>
                        <div className={classes.amenity}><p><b>Sports :</b> </p> <p className={classes.amenityVal}>{details.SPORTS?.split(',').map((sport, index) => (<li key={index}>{sport}</li>))}</p></div>
                        <div className={classes.amenity}><p><b>Safety :</b> </p> <p>{details.SAFETY?.split(',').map((safety, index) => (<li key={index}>{safety}</li>))}</p></div>
                    </section>
                    <section className={classes.card2} id="specifications">
                        <h3>{details.PROJECT_NAME} - Specifications</h3>
                        <div className={classes.underline}></div>
                        <p><b>Master Bedroom-Walls :</b> {details.B_WALLS}</p>
                        <p><b>Master Bedroom-Flooring :</b> {details.B_FLOOR}</p>
                        <p><b>Other Bedrooms-Flooring :</b> {details.OTHER_BFLOOR}</p>
                        <p><b>Walls :</b>  {details.WALLS}</p>
                        <p><b>Living Area-Flooring :</b> {details.L_FLOOR}</p>
                        <p><b>Bathroom :</b> {details.BATHROOM}</p>
                        <p><b>Structure :</b> {details.STRUCTURE}</p>
                    </section>
                    <section className={classes.card2} id="propertyInfo">
                        <div className={classes.top2}>
                            <h3>Property Information :</h3>
                            <div className={classes.underline}></div>
                            <div className={classes.top2l}>
                                <div className={classes.row}>
                                    <div className={classes.info}>
                                        <p>RERA Reg </p>
                                        <p><b>{details.RERA}</b></p>
                                    </div>
                                    <div className={classes.info}>
                                        <p>Listing Type</p>
                                        <p><b>{details.LISTING_TYPE}</b></p>
                                    </div>
                                    <div className={classes.info}>
                                        <p>Property Type</p>
                                        <p><b>{details.PROPERTY_TYPE}</b></p>
                                    </div>

                                </div>
                                <div className={classes.row}>
                                    <div className={classes.info}>
                                        <p>City</p>
                                        <p><b>{details.CITY}</b></p>
                                    </div>
                                    <div className={classes.info}>
                                        <p>Locality</p>
                                        <p><b>{details.LOCALITY}</b></p>
                                    </div>
                                    <div className={classes.info}>
                                        <p>Area</p>
                                        <p><b>{details.MIN_SQFT} - {details.MAX_SQFT}</b></p>
                                    </div>
                                </div>
                                <div className={classes.row}>
                                    <div className={classes.info}>
                                        <p>Price</p>
                                        <p><b>{details.MIN_PRICE} - {details.MIN_PRICE}</b></p>
                                    </div>
                                    <div className={classes.info2}>
                                        <p>Possession Status</p>
                                        <p><b>{details.POSSESSION}</b></p>
                                    </div>

                                </div>
                                <div className={classes.row}>
                                    <div className={classes.detailed}>
                                        <p>Detailed Address</p>
                                        <p><b>{details.D_ADDRESS}</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>}
            {details2 && <div className={classes.container}>
                <div className={classes.top1}>
                    <div className={classes.left}>
                        <div className={classes.leftTop}>
                            {selectedImage2 && (
                                <div className={classes.modal} onClick={() => setSelectedImage2(null)}>
                                    <img src={selectedImage2} alt="Selected Cover" />
                                </div>
                            )}
                            {!selectedImage2 && (
                                <div className={classes.modal}>
                                    <img src={`https://primeedgeproperties.in/BUILDER/${details2.COVER_IMAGE.split(',')[0]}`} alt="Default Cover" />
                                </div>
                            )}
                        </div>
                        <div className={classes.coverImages}>
                            {details2.COVER_IMAGE.split(',').map((cover, index) => (
                                <img
                                    key={index}
                                    src={`https://primeedgeproperties.in/BUILDER/${cover}`}
                                    alt={`Cover ${index}`}
                                    onClick={() => setSelectedImage2(`https://primeedgeproperties.in/BUILDER/${cover}`)}
                                />
                            ))}
                        </div>
                    </div>
                    <div className={classes.right}>
                        <h1>{details2.PROPERTY_NAME}</h1>
                        <p>{details2.LOCALITY} , {details2.CITY}</p>
                        <h1>₹ {details2.PRICE}</h1>
                        <img className={classes.star} src={star} alt="5 star rating" />
                        <p>Status: <b>{details2.POSSESSION_STATUS}</b></p>
                        <p><i class="fa-solid fa-bed"></i> {details2.BHK}</p>
                        <p><i class="fa-solid fa-couch"></i> {details2.FURNISHING}</p>
                        <p><i class="fa-solid fa-bath"></i> {details2.FURNISHING} Bathroom</p>
                        <p><i class="fa-solid fa-house"></i> {details2.SQFT} Sq.Ft. (Carpet Area)</p>
                        <Link onClick={() => details.PROJECT_NAME(details2.PROPERTY_NAME)} to="/contactus" target="_blank" className={classes.CallBTN}>Request a Call Back</Link>

                    </div>
                </div>
                <div className={classes.top2client}>
                    <div className={classes.left}>
                        <div className={classes.card}>
                            <h3>Property Information</h3>
                            <div className={classes.underline}></div>
                            <div className={classes.row}>
                                <div className={classes.column}>
                                    <p>Listing Type</p>
                                    <p><b>{details2.CUST_PURP}</b></p>
                                </div>
                                <div className={classes.column}>
                                    <p>Property Type</p>
                                    <p><b>{details2.PROPERTY_TYPE}</b></p>
                                </div>
                                <div className={classes.column}>
                                    <p>Building Type</p>
                                    <p><b>{details2.BUILDING_TYPE}</b></p>
                                </div>
                            </div>
                            <div className={classes.row}>
                                <div className={classes.column}>
                                    <p>City</p>
                                    <p><b>{details2.CITY}</b></p>
                                </div>
                                <div className={classes.column}>
                                    <p>Locality</p>
                                    <p><b>{details2.LOCALITY}</b></p>
                                </div>
                                <div className={classes.column}>
                                    <p>Area</p>
                                    <p><b>{details2.SQFT} Sq.Ft. (Carpet Area)</b></p>
                                </div>
                            </div>
                            <div className={classes.row}>
                                <div className={classes.column}>
                                    <p>Price</p>
                                    <p><b>{details2.PRICE}</b></p>
                                </div>
                                <div className={classes.column}>
                                    <p>Possession Status</p>
                                    <p><b>{details2.POSSESSION_STATUS}</b></p>
                                </div>
                                <div className={classes.column}>
                                    <p>Furnishing Status</p>
                                    <p><b>{details2.FURNISHING}</b></p>
                                </div>
                            </div>
                            <div className={classes.row}>
                                <div className={classes.column}>
                                    <p>Number of Bhk</p>
                                    <p><b>{details2.BHK}</b></p>
                                </div>
                                <div className={classes.column}>
                                    <p>Number of Bathroom</p>
                                    <p><b>{details2.BATHROOM}</b></p>
                                </div>
                                <div className={classes.column}>
                                    <p>Covered Parking</p>
                                    <p><b>{details2.PARKING}</b></p>
                                </div>
                            </div>
                            <div className={classes.row}>
                                <div className={classes.column}>
                                    <p>Floor Number</p>
                                    <p><b>{details2.FLOOR_NO}</b></p>
                                </div>
                                <div className={classes.column}>
                                    <p>Total Floor Count</p>
                                    <p><b>{details2.TOTAL_FLOOR}</b></p>
                                </div>
                                <div className={classes.column}>
                                    <p>Age of Property (Years)</p>
                                    <p><b>{details2.AGE_OF_PROPERTY}</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.right}></div>
                </div>
            </div>}

        </>
    );
}

export default SearchDetails;
