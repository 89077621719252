import classes from "./AccountInfo.module.css";
import axios from "axios"
import { useEffect, useState } from "react"

const AccountInfo = () => {

    const [info, setInfo] = useState([]);

    const fetchAccountInfo = async () => {
        try {
            const res = await axios.get("https://primeedgeproperties.in/fetchAccountInfo")
            console.log(res.data)
            setInfo(res.data)
        } catch (err) {
            console.log(err)
        }

    }

    useEffect(() => {
        fetchAccountInfo();
    }, []);
    return (
        <>
            <section className={classes.container}>
                <h1>Users Info</h1>
                <div className={classes.table_Container}>
                    <table>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Password</th>
                        </tr>
                        {info && info.map((item, index) => (
                            <tr key={index}>
                                <td>{item.ID}</td>
                                <td>{item.NAME}</td>
                                <td>{item.EMAIL}</td>
                                <td>{item.PASSWORD}</td>
                            </tr>
                        ))}
                    </table>
                </div>
            </section>
        </>
    );
};
export default AccountInfo;