import classes from "./Client_side_request.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';

import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import Popup from "../../Popup/Popup";


const Client_side_request = () => {
    const [info, setInfo] = useState();
    const [cookies, setCookie, removeCookie] = useCookies(['edit2']);
    const [dummy, setDummy] = useState(false)
    const [user, setUser] = useState("Individual User");
    const [agentInfo, setAgentInfo] = useState();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpen2, setIsPopupOpen2] = useState(false);
    const [delID, setDelID] = useState();

    const fetchUserInfo = async () => {
        try {
            const res = await axios.get("https://primeedgeproperties.in/client_requests")
            setInfo(res.data);
            console.log(res.data)
        } catch (err) {
            console.log(err);
        }
    };
    const fetchAgentInfo = async () => {
        try {
            const res = await axios.get("https://primeedgeproperties.in/agent_requests")
            setAgentInfo(res.data)
            console.log(res.data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchUserInfo();
        fetchAgentInfo();
    }, [dummy, user]);

    //dropdown handle 
    const handleUser = (e) => {
        setUser(e.target.value)
        //console.log(e.target.value)
    }


    const active = async (btnId) => {
        //console.log(btnId)
        try {
            //setCol(!col);
            const res = await axios.put("https://primeedgeproperties.in/status", { btnId })
            //console.log(res)
            setDummy(!dummy)

        } catch (err) {
            return console.log(err);
        }
    }

    const active2 = async (ID) => {
        try {
            //setCol(!col);
            const res = await axios.put("https://primeedgeproperties.in/Agent_status", { ID })
            //console.log(res)
            setDummy(!dummy)

        } catch (err) {
            return console.log(err);
        }
    }

    const editHnd = (id) => {
        console.log(id)
        setCookie('edit', id)

        const newTabUrl = 'https://primeedgeproperties.in/adUserEdit';

        // Open a new tab with the specified URL
        window.open(newTabUrl, '_blank');
    }
    const agent_editHnd = (id) => {
        console.log(id)
        setCookie('edit', id)

        const newTabUrl = 'https://primeedgeproperties.in/adAgentEdit';

        // Open a new tab with the specified URL
        window.open(newTabUrl, '_blank');
    }
    //for delete
    const delhandle = (PROP_ID) => {
        //console.log(PROP_ID,":LP")
        setIsPopupOpen(true)
        setDelID(PROP_ID)
    }
    const closePopup = () => {
        setIsPopupOpen(false);
    };
    const DeletePopUp = async () => {
        console.log(delID, ":LP")
        try {
            const res = await axios.post("https://primeedgeproperties.in/userDeleteClientSide", { delID })
            console.log(res)
            setIsPopupOpen(false)
            setDummy(!dummy)
        } catch (err) {
            console.log(err)
        }
    };

    //agent delete
    const delhandle2 = (PROP_ID) => {
        //console.log(PROP_ID,":LP")
        setIsPopupOpen2(true)
        setDelID(PROP_ID)
    }
    const closePopup2 = () => {
        setIsPopupOpen2(false);
    };
    const DeletePopUp2 = async () => {
        console.log(delID, ":LP")
        try {
            const res = await axios.post("https://primeedgeproperties.in/userDeleteClientSide2", { delID })
            console.log(res)
            setIsPopupOpen2(false)
            setDummy(!dummy)
        } catch (err) {
            console.log(err)
        }
    };



    return (
        <>


            <div className={classes.main_container}>
                <h1>Client Project Requests</h1>
                <Link to="/dashboard" className={classes.backBtn}>Back to Dashboard</Link>

                <div className={classes.dropdown}>
                    <select onChange={handleUser}>
                        <option>Individual User</option>
                        <option>Agent</option>
                    </select>
                </div>

                {user === "Individual User" && info && info.data.map((record, index) => (
                    <div className={classes.card} key={index}>
                        <div className={classes.left}>
                            <div className={classes.L1}>
                                <p className={classes.area} ><b>No :</b> <span className={classes.id}>{record.ID}</span></p>
                                <p className={classes.area}><b>Cust Purpose : </b> {record.CUST_PURP}</p>
                                <p className={classes.area}><b>Property Owner</b> {record.PROPERTY_OWNER}</p>
                                <p className={classes.area}><b>Owner Name :</b> {record.OWNER_NAME}</p>
                                <p className={classes.area}><b>Bhk : {record.BHK}</b></p>
                                <p className={classes.area}><b>Contact Num : </b>{record.CONTACT}</p>

                            </div>
                            <div className={classes.R1}>
                                <p ><b>Building/property Name</b> {record.PROPERTY_NAME}</p>
                                <p><b>property Type : </b> {record.PROPERTY_TYPE}</p>
                                <p className={classes.area}><b>Sqft :</b> {record.SQFT}</p>
                                <p className={classes.area}><b>Price : </b>{record.PRICE}</p>
                                <p className={classes.area}><b>Locality :</b> {record.LOCALITY}</p>
                                <p className={classes.area}><b>City :</b> {record.CITY}</p>

                            </div>
                        </div>
                        <div className={classes.right}>
                            <button onClick={() => active(record.SIGNUP_ID)} className={`${record.STATUS ? classes.firstclass : classes.secondclass}`} >{record.STATUS ? 'Deactivate' : 'Activate'}</button>
                            <button className={classes.edit} onClick={() => { editHnd(record.SIGNUP_ID) }}>Edit</button>
                            <button className={classes.delete} onClick={() => { delhandle(record.PROP_ID) }}>Delete</button>

                        </div>
                    </div>
                ))}{/*end userInfo */}

                {user === "Agent" && agentInfo && agentInfo.map((record, index) => (
                    <div className={classes.card} key={index}>
                        <div className={classes.left}>
                            <div className={classes.L1}>
                                <p className={classes.area} ><b>No :</b> <span className={classes.id}>{record.ID}</span></p>
                                <p className={classes.area}><b>Cust Purpose : </b> {record.CUST_PURP}</p>
                                <p className={classes.area}><b>Property Owner</b> {record.PROPERTY_OWNER}</p>
                                <p className={classes.area}><b>Owner Name :</b> {record.OWNER_NAME}</p>
                                <p className={classes.area}><b>Bhk : {record.BHK}</b></p>
                                <p className={classes.area}><b>Contact Num : </b>{record.CONTACT}</p>

                            </div>
                            <div className={classes.R1}>
                                <p ><b>Building/property Name</b> {record.PROPERTY_NAME}</p>
                                <p><b>property Type : </b> {record.PROPERTY_TYPE}</p>
                                <p className={classes.area}><b>Sqft :</b> {record.SQFT}</p>
                                <p className={classes.area}><b>Price : </b>{record.PRICE}</p>
                                <p className={classes.area}><b>Locality :</b> {record.LOCALITY}</p>
                                <p className={classes.area}><b>City :</b> {record.CITY}</p>

                            </div>
                        </div>
                        <div className={classes.right}>
                            <button onClick={() => active2(record.PROP_ID)} className={`${record.STATUS ? classes.firstclass : classes.secondclass}`} >{record.STATUS ? 'Deactivate' : 'Activate'}</button>
                            <button className={classes.edit} onClick={() => { agent_editHnd(record.PROP_ID) }}>Edit</button>
                            <button className={classes.delete} onClick={() => { delhandle2(record.PROP_ID) }}>Delete</button>
                        </div>
                    </div>
                ))}
            </div>
            {/* {info?.user.map((item, key) => <p>{key}----{item.PASSWORD}---{item.EMAIL}</p>)}*/}

            {isPopupOpen && <Popup onClose={closePopup} onDelete={DeletePopUp} txt1='Please Confirm!' txt2='This action will permanently delete this property' AdminDel='.' />}
            {isPopupOpen2 && <Popup onClose={closePopup2} onDelete={DeletePopUp2} txt1='Please Confirm!' txt2='This action will permanently delete this property' AdminDel='.' />}
        </>
    );
};
export default Client_side_request;