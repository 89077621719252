import classes from "./Mission.module.css";
import mission from "./Images/[removal.ai]_d155f983-75e9-45cb-96a3-dc6be59d85f3-13146546_happy-people-watching-rocket-launch.png";
import vision from "./Images/9175334_6534-PhotoRoom.png-PhotoRoom.png";

const Mission=()=>{
    return(
        <div className={classes.container}>
            <div className={classes.card}>
                <img src={mission} alt="prime edge properties" />
                <h3>Our Mission</h3>
                <p>To focus with a strong commitment towards customer satisfaction and expectation.</p>
            </div>
            <div className={classes.card}>
            <img src={vision} alt="prime edge properties" />
            <h3>Our Vision</h3>
            <p>To be a reliable partner for stress-free property you want.</p>
            </div>
        </div>
    );
};
export default Mission;