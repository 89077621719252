import classes from "./Sellpage.module.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useEffect, useState } from "react";
import axios from "axios"
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';

const Projectpage = () => {
    const [selldata, setSelldata] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    const Property_name_Cookie = (propertyname) => {
        setCookie('NameOfProperty', propertyname, { path: '/' })
    }

    useEffect(() => {
        const fetchSellData = async () => {
            try {
                const res = await axios.get("https://primeedgeproperties.in/navProject")
                setSelldata(res.data)
            } catch (err) {
                console.log(err)
            }
        }
        fetchSellData()
    }, []);

    const hndClk = (rera, id) => {
        // const rera = pro;
        console.log(rera, id)
        setCookie('detail', rera)
        setCookie('detailId', id)

        const newTabUrl = 'https://primeedgeproperties.in/propDetails';

        // Open a new tab with the specified URL
        window.open(newTabUrl, '_blank');
    };
    return (
        <>

            <section className={classes.container}>
                <div className={classes.left}>
                    {selldata && selldata.map((item, index) => (
                        <div className={classes.card} key={index}>
                            <div className={classes.Card_top}>
                                <div className={classes.card_left}>
                                    <img src={`https://primeedgeproperties.in/BUILDER/` + item.THUMBNAIL} />
                                </div>
                                <div className={classes.card_right}>
                                    <p className={classes.Pname}>{item.PROJECT_NAME}</p>
                                    <p className={classes.small_font}>{item.BHK} for Sale in {item.LOCALITY} , {item.CITY}</p>
                                    <p className={classes.Pname}>₹ {item.MIN_PRICE} - {item.MAX_PRICE}</p>
                                    <div className={classes.row}>
                                        <div><p className={classes.small_font}>{item.MIN_SQFT} - {item.MAX_SQFT} SQFT</p> <p className={classes.small}>Built-up Area</p></div> :
                                        <div><p className={classes.small_font}>{item.POSSESSION}</p> <p className={classes.small}>Possesion Status</p></div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.Card_bottom}>
                                <div><p className={classes.Owner_name}>{item.OWNER_NAME} <p className={classes.name}>{item.CUST_TYPE}</p></p></div>
                                <div><button onClick={() => { hndClk(item.RERA, item.ID) }} className={classes.Dbtn}>Details</button> <Link onClick={() => { Property_name_Cookie(item.PROJECT_NAME) }} to="/contactus" target="_blank" className={classes.Cbtn}>Contact</Link></div>
                            </div>
                        </div>
                    ))}

                </div>
                <div className={classes.right}></div>
            </section>

        </>
    );
};
export default Projectpage;