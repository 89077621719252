import classes from "./Sellpage.module.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useEffect, useState } from "react";
import axios from "axios"
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';

const Rentpage = () => {
    const [selldata, setSelldata] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(['navD']);

    const Property_name_Cookie = (propertyname) => {
        setCookie('NameOfProperty', propertyname, { path: '/' })
    }

    useEffect(() => {
        const fetchSellData = async () => {
            try {
                const res = await axios.get("https://primeedgeproperties.in/navRent")
                console.log(res.data)
                setSelldata(res.data)
            } catch (err) {
                console.log(err)
            }
        }
        fetchSellData()
    }, []); // Empty dependency array to fetch data only once

    const handleDetails = (SIGNUP_ID) => {
        setCookie('navD', SIGNUP_ID)
        const newTabUrl = 'https://primeedgeproperties.in/Sell-Rent/Details';
        window.open(newTabUrl, '_blank');
    }

    return (
        <>
            <section className={classes.container}>
                <div className={classes.left}>
                    {selldata.map((item, index) => (
                        <div className={classes.card} key={index}>
                            <div className={classes.Card_top}>
                                <div className={classes.card_left}>
                                    <img src={`https://primeedgeproperties.in/BUILDER/` + item.PROP_IMAGE} alt={item.PROPERTY_NAME} />
                                </div>
                                <div className={classes.card_right}>
                                    <p className={classes.Pname}>{item.PROPERTY_NAME}</p>
                                    <p className={classes.small_font}>{item.BHK} for {item.CUST_PURP} in {item.LOCALITY} , {item.CITY}</p>
                                    <p className={classes.Pname}>₹ {item.PRICE}</p>
                                    <div className={classes.row}>
                                        <div><p className={classes.small_font}>{item.SQFT} SQFT</p> <p className={classes.small}>Built-up Area</p></div> :
                                        <div><p className={classes.small_font}>{item.POSSESSION_STATUS}</p> <p className={classes.small}>Possesion Status</p></div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.Card_bottom}>
                                <div><button onClick={() => { handleDetails(item.PROP_ID) }} className={classes.Dbtn}>Details</button> <Link onClick={() => Property_name_Cookie(item.PROPERTY_NAME)} to="/contactus" target="_blank" className={classes.Cbtn}>Contact</Link></div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={classes.right}></div>
            </section>
        </>
    );
};
export default Rentpage;
