import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import classes from "./ProjectDetail.module.css";
import star from "./Images/5star.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import imageblg from "./Images/building.png";
import { Link } from "react-router-dom";


function ProjectDetail() {
  const { ID } = useParams();
  const { RERA } = useParams();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);



  useEffect(() => {
    axios.get(`https://primeedgeproperties.in/projectD/` + ID)
      .then(res => {
        setData(res.data.data1); setData2(res.data.data2);
        console.log(data)
      })
      .catch(err => console.log(err))
  }, []);


  const myarr = data.map(item => item.BHK);
  //const myarr2 = myarr[0].split(",")
  //const [a1, a2] = myarr2.split(',')
  console.log(myarr[0])

  return (
    <>

      {data && data.map((item) => (
        <div className={classes.container}>

          <div className={classes.top1}>
            <div className={classes.left} >
              <div>
                {item.COVER.split(',').map((cover, index) => (
                  <img className={classes.image} key={index} src={`https://primeedgeproperties.in/BUILDER/${cover}`} alt={`Cover ${index}`} />
                ))}
              </div>
            </div>
            <div className={classes.right}>
              <h1>{item.PROJECT_NAME}</h1>
              <p>{item.LOCALITY},{item.CITY}</p>
              <hr />
              <h2>₹ {item.MIN_PRICE} - {item.MAX_PRICE}</h2>
              <img className={classes.star} src={star} alt="5 star rating" />
              <p>Status : <b>{item.POSSESSION}</b></p>
              <div className={classes.row}>
                <p>{item.BHK} </p>


                <p>{item.MIN_SQFT} - {item.MAX_SQFT} SQ.FT (Carpet)</p>
              </div>
              <div className={classes.psize}>
                <div className={classes.logimg}><img src={imageblg} alt="Load" /></div>
                <div>
                  <p>Project Size :</p>
                  <p>{item.TOTAL_UNITS} units ✦  {item.TOTAL_ACRES} Acres</p>
                </div>
              </div>

              <button className={classes.btn}>Request a Call Back</button>
            </div>
          </div>
          <div className={classes.tabs}>
            <div>
              <h3>{item.PROJECT_NAME} - Project Info</h3>
              <p>{item.OVERVIEW}</p>
            </div>

            <div>
              <h3>{item.PROJECT_NAME} - Regulatory Information</h3>
              <h4>Project RERA Reg.</h4><p>{item.RERA}</p>
            </div>

            <div>
              <h3>{item.PROJECT_NAME} Brochure</h3>
              {item.BROCHURE.split(',').map((cover, index) => (
                <img className={classes.image} key={index} src={`https://primeedgeproperties.in/BUILDER/${cover}`} alt={`Cover ${index}`} />
              ))}
            </div>

            <div>
              <h3>{item.PROJECT_NAME} Video</h3>
              <video width="750" height="500" controls >
                <source src={`https://primeedgeproperties.in/BUILDER/` + item.VIDEO} type="video/mp4" />
              </video>

            </div>

            <div>
              <h3>{item.PROJECT_NAME} - Amenities</h3>
              <p>Sports : </p><p>{item.SPORTS}</p>
              <p>Safety : </p><p>{item.SAFETY}</p>
            </div>

            <div>
              <h3>{item.PROJECT_NAME} - Specifications</h3>
              <p><b>Master Bedroom-Walls :</b> {item.B_WALLS}</p>
              <p><b>Master Bedroom-Flooring :</b> {item.B_FLOOR}</p>
              <p><b>Other Bedrooms-Flooring :</b> {item.OTHER_BFLOOR}</p>
              <p><b>Walls :</b>  {item.WALLS}</p>
              <p><b>Living Area-Flooring :</b> {item.L_FLOOR}</p>
              <p><b>Bathroom :</b> {item.BATHROOM}</p>
              <p><b>Structure :</b> {item.STRUCTURE}</p>


            </div>

          </div>




          <div className={classes.top2}>
            <h3>Property Information :</h3>
            <div className={classes.top2l}>
              <div className={classes.row}>
                <div className={classes.info}>
                  <p>RERA Reg </p>
                  <p><b>{item.RERA}</b></p>
                </div>
                <div className={classes.info}>
                  <p>Listing Type</p>
                  <p><b>{item.LISTING_TYPE}</b></p>
                </div>
                <div className={classes.info}>
                  <p>Property Type</p>
                  <p><b>{item.PROPERTY_TYPE}</b></p>
                </div>

              </div>
              <div className={classes.row}>
                <div className={classes.info}>
                  <p>City</p>
                  <p><b>{item.CITY}</b></p>
                </div>
                <div className={classes.info}>
                  <p>Locality</p>
                  <p><b>{item.LOCALITY}</b></p>
                </div>
                <div className={classes.info}>
                  <p>Area</p>
                  <p><b>{item.MIN_SQFT} - {item.MAX_SQFT}</b></p>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.info}>
                  <p>Price</p>
                  <p><b>{item.MIN_PRICE} - {item.MIN_PRICE}</b></p>
                </div>
                <div className={classes.info}>
                  <p>Possession Status</p>
                  <p><b>{item.POSSESSION}</b></p>
                </div>
                <div className={classes.info}>
                  <p>BHK</p>
                  <p><b>{item.BHK}</b></p>
                </div>
              </div>

              <div className={classes.row}>
                <div className={classes.detailed}>
                  <p>Detailed Address</p>
                  <p><b>{item.D_ADDRESS}</b></p>
                </div>

              </div>
              <div className={classes.rates}>

                <table>
                  <tr>
                    <th>Unit Type</th>
                    <th>Area</th>
                    <th>Price</th>
                  </tr>
                  {data2 && data2.map(ab =>
                    <tr>
                      <td><Link className={classes.lnk} to={`/projectD_bhk/${ab.ID}`} target="_blank">{ab.B_UNIT} Bhk </Link></td>
                      <td><Link className={classes.lnk} to={`/projectD_bhk/${ab.ID}`} target="_blank">{ab.B_SQFT} Sq.Ft. </Link></td>
                      <td><Link className={classes.lnk} to={`/projectD_bhk/${ab.ID}`} target="_blank">{ab.B_PRICE} </Link></td>
                    </tr>
                  )}
                </table>

              </div>

            </div>
          </div>
        </div>
      ))}





    </>
  );
};
export default ProjectDetail;