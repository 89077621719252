import axios from "axios";
import classes from "./Enquiry.module.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"

const Enquiry = () => {
    const [result, setResult] = useState('');


    const getAllEnquiry = async () => {
        try {
            const res = await axios.get('https://primeedgeproperties.in/fetchEnquiryData')
            console.log(res.data)
            setResult(res.data)
        } catch (err) {
            console.log(err)
        }
    };

    useEffect(() => {
        getAllEnquiry()
    }, []);
    return (
        <>
            <Link to="/dashboard" className={classes.backBtn}>Back to Dashboard</Link>
            <div className={classes.container}>
                <table className={classes.outerTable}>
                    <tr>
                        <th>Enquiry NO .</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Contact Number</th>
                        <th>Property Name</th>
                        <th>Interested</th>
                        <th>Date/Time</th>
                    </tr>
                    {result && result.map((item, index) => (
                        <tr key={index}>
                            <td>{item.ENQUIRY_NO}</td>
                            <td>{item.NAME}</td>
                            <td>{item.EMAIL}</td>
                            <td>{item.MOBILE}</td>
                            <td>{item.PROPERTY_NAME || item.PROJECT_NAME}</td>
                            <td>{item.INTEREST}</td>
                            <td>{formatDateTime(item.TIME)}</td>
                        </tr>
                    ))}

                </table>
            </div>
        </>
    );
};


// Function to format date and time
const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export default Enquiry;