import { Link } from "react-router-dom";
import classes from "./Footer.module.css";
import fb from "./Image/facebook.png";
import insta from "./Image/instagram.png";
import linkdin from "./Image/linkedin.png";
import Cookies from "js-cookie";
import Disclaimer from "../ADMIN/Disclaimer";
import footLOGO from "./Image/LOGO.png";

const Footer = (props) => {

    //admin status Cookie
    const adminSuccess = Cookies.get('adminStatus')


    return (
        <footer className={classes.footer}>
            <div className={classes.top}>
                <div className={classes.r1}>
                    <img src={footLOGO} alt="PrimeEdge" />
                    <p>Shop No. 02, Manish Park (1), Opp. Bramha Exuberance, NIBM Road, Kondhwa, Pune - 48</p>
                </div>
                <div className={classes.r2}>
                    <h3>COMPANY</h3>
                    <p><Link className={classes.lnkStyle} to="about">About Us</Link></p>
                    <p><Link className={classes.lnkStyle} to='contactus'>Contact Us</Link></p>
                </div>
                <div className={classes.r2}>
                    <h3>EXPLORE</h3>
                    <p><Link className={classes.lnkStyle} to="/navallproject">Properties</Link></p>
                    <p><Link className={classes.lnkStyle} to="/navsell">Sell Properties</Link></p>
                    {adminSuccess === 'true' && <p className={classes.admin}><Link to="/dashboard" className={classes.admin}>Dashboard</Link></p>}
                </div>
                <div className={classes.r4}>
                    <h3>KEEP IN TOUCH</h3>
                    <img src={fb} alt="Prime Edge Properties" />
                    <img src={insta} alt="Prime Edge Properties" />
                    <img src={linkdin} alt="Prime Edge Properties" />
                </div>
            </div>

            <div className={classes.disclaimerHold}><Disclaimer></Disclaimer></div>

            <div className={classes.bottom}>
                <p>Copyright © 2024 PrimeEdgeProperties | Powered by EXADS IT SOLUTIONS PVT. LTD</p>
            </div>
        </footer>
    );
};
export default Footer;